// ajax default send type setting
var commonSendType = 'post';
if (location.port == "3000") commonSendType = 'get';
//console.log("Ajax Type :" + commonSendType);

// browser supported check (popup)
var browse_check = function () {
	if (!document.getElementById("modal_browse_supported_guide")) return false;

	var browser_ver = navigator.userAgent,
		filter = /(msie) [2-9]/i;

	var _this = browse_check,
		layer = {
			popup_layer: document.getElementById("modal_browse_supported_guide"),
			popup_back_layer: document.getElementById("modal-background"),
			popup_close: document.getElementById("modal-layer-close")
		},
		classSet = {
			activate: "active",
			popup_layer: "broswe-check-popup-layer",
			popup_back_layer: "modal-background"
		};

	_this.init = function () {
		if (browser_ver.search(filter) !== -1) {
			//console.log("ie lower case");
			_this.layer_active();
		}
		_this.addEvent();
	};

	_this.addEvent = function () {
		layer.popup_back_layer.onclick = function () {
			_this.layer_close();
		};
		layer.popup_close.onclick = function () {
			_this.layer_close();
		};
	};

	_this.layer_active = function () {
		layer.popup_layer.className = classSet.popup_layer + " " + classSet.activate;
		layer.popup_back_layer.className = classSet.popup_back_layer + " " + classSet.activate;
	};

	_this.layer_close = function () {
		if (layer.popup_layer.className.indexOf("active") > 1) {
			layer.popup_layer.className = classSet.popup_layer;
			layer.popup_back_layer.className = classSet.popup_back_layer;
		}
	};
	_this.init();
};
browse_check();

// Scripts that prevent users from entering script code
var xssfilter = function (content, isHTML) {
	if(typeof content == 'string' || isHTML) {
		// Do not change the running order below.
		
		var newContent = "";
		var contentArry = content.split("&");
		var orgPassword = "";
		
		for(var x in contentArry) {
		    if(contentArry[x].indexOf("dkagh=") > -1
		    		|| contentArry[x].indexOf("password=") > -1
		    		|| contentArry[x].indexOf("passwordConfirm=") > -1
		    		|| contentArry[x].indexOf("txtBoxPasswd=") > -1
		    		|| contentArry[x].indexOf("txtBoxOldPasswd=") > -1
		    		|| contentArry[x].indexOf("txtBoxNewPasswd=") > -1
		    		|| contentArry[x].indexOf("txtBoxNewPasswdConfirm=") > -1) {
		    	orgPassword += "&" + contentArry[x];
		    } else {
		    	if(x == 0) {
		    		newContent = contentArry[x];
		    	} else {
		    		newContent += "&" + contentArry[x];
		    	}
		    }
		}
			
		var returnTxt =  newContent.replace(/%3C/g, '').replace(/%3E/g, '') // <와 > 삭제 (for url)
						.replace(/javascript%3A/gi, '') // javascript: 을 삭제 (for url, 대소문자 구분없이)
						.replace(/%22/g, '').replace(/%27/g, '') // "와 '를 삭제 (for url)
						.replace(/¼/g, '<').replace(/¾/g, '>')
						.replace(/\+ADw\-/g, '<').replace(/\+AD4\-/g, '>')
						.replace(/\0/gi, ' ').replace(/&#x09;/g, '').replace(/&#x0A;/g, '').replace(/&#x0D;/g, '') // 공백 대체 문자 제거
						.replace(/j( *	*\\*<*>*|\/\*.*\*\/)a( *	*\\*<*>*|\/\*.*\*\/)v( *	*\\*<*>*|\/\*.*\*\/)a( *	*\\*<*>*|\/\*.*\*\/)s( *	*\\*<*>*|\/\*.*\*\/)c( *	*\\*<*>*|\/\*.*\*\/)r( *	*\\*<*>*|\/\*.*\*\/)i( *	*\\*<*>*|\/\*.*\*\/)p( *	*\\*<*>*|\/\*.*\*\/)t( *	*\\*<*>*|\/\*.*\*\/):/gi, '') // javascript: 제거 (대소문자 구분 없이)
						.replace(/v( *	*\\*<*>*|\/\*.*\*\/)b( *	*\\*<*>*|\/\*.*\*\/)s( *	*\\*<*>*|\/\*.*\*\/)c( *	*\\*<*>*|\/\*.*\*\/)r( *	*\\*<*>*|\/\*.*\*\/)i( *	*\\*<*>*|\/\*.*\*\/)p( *	*\\*<*>*|\/\*.*\*\/)t( *	*\\*<*>*|\/\*.*\*\/):/gi, '') // vbscript: 제거 (대소문자 구분 없이)
						.replace(/l( *	*\\*<*>*|\/\*.*\*\/)i( *	*\\*<*>*|\/\*.*\*\/)v( *	*\\*<*>*|\/\*.*\*\/)e( *	*\\*<*>*|\/\*.*\*\/)s( *	*\\*<*>*|\/\*.*\*\/)c( *	*\\*<*>*|\/\*.*\*\/)r( *	*\\*<*>*|\/\*.*\*\/)i( *	*\\*<*>*|\/\*.*\*\/)p( *	*\\*<*>*|\/\*.*\*\/)t( *	*\\*<*>*|\/\*.*\*\/):/gi, '') // livescript: 제거 (대소문자 구분 없이)
						.replace(/e( *	*\\*<*>*|\/\*.*\*\/)x( *	*\\*<*>*|\/\*.*\*\/)p( *	*\\*<*>*|\/\*.*\*\/)r( *	*\\*<*>*|\/\*.*\*\/)e( *	*\\*<*>*|\/\*.*\*\/)s( *	*\\*<*>*|\/\*.*\*\/)s( *	*\\*<*>*|\/\*.*\*\/)i( *	*\\*<*>*|\/\*.*\*\/)o( *	*\\*<*>*|\/\*.*\*\/)n( *	*\\*<*>*|\/\*.*\*\/)\(/gi, '') // expression( 제거 (대소문자 구분 없이)
						.replace(/&#[x]*[0-9]+/gi, '.') // encoding 방지
						.replace(/<script.+\/script>/gi, '') // script로 시작해서 /script로 끝나는 태그 제거 (대소문자 구분없이)
						.replace(/String\.fromCharCode/gi, '')
						.replace(/Set\.constructor/gi, '')
						.replace(/FSCommand/gi, '')
						.replace(/seekSegmentTime/gi, '')
						.replace(/eval\(/gi, '')
						.replace(/window\.on.+/gi, '')
						;

		// tag
		returnTxt = returnTxt.replace(/<link./gi, '').replace(/<\/link>/gi, '')
						.replace(/<script./gi, '').replace(/<\/script>/gi, '')
						.replace(/<style./gi, '').replace(/<\/style>/gi, '')
						.replace(/<meta./gi, '').replace(/<\/meta>/gi, '')
						.replace(/<object./gi, '').replace(/<\/object>/gi, '')
						.replace(/<embed./gi, '').replace(/<\/embed>/gi, '')
						.replace(/<iframe./gi, '').replace(/<\/iframe>/gi, '')
						.replace(/<applet./gi, '').replace(/<\/applet>/gi, '')
						.replace(/<base./gi, '').replace(/<\/base>/gi, '')
						.replace(/<frameset./gi, '').replace(/<\/frameset>/gi, '')
						.replace(/<xml./gi, '').replace(/<\/xml>/gi, '')
						;

		// form, input
		if(isHTML=='form') {
			returnTxt = returnTxt.replace(/(\%20| |	|\\|\/|\"|\'|\.)+on[a-z]+\=/gi, '');
			returnTxt = returnTxt.replace(/\(/g, '').replace(/\)/g, '');
		} else {
			//returnTxt = returnTxt.replace(/(\%20|	|\\|\/|\"|\'|\.)+on/gi, '&#111;n');
			//returnTxt = returnTxt.replace(/(\%20|	|\\|\/|\"|\'|\.)+(?=on[^a-zA-Z0-9])/gi, '&#111;n');
			var emailPattern = /([a-zA-Z0-9._%+-]+(%40|@)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
			var emails = [];
			returnTxt = returnTxt.replace(emailPattern, function(match) {
				var encodedEmail = match.replace('%40', '@');
				emails.push(encodedEmail);
				return '__EMAIL_PROTECTED__' + emails.length + '__END_EMAIL__'; 
			});

			returnTxt = returnTxt.replace(/(\%20|	|\\|\/|\"|\'|\.)+on/gi, '&#111;n');

			returnTxt = returnTxt.replace(/__EMAIL_PROTECTED__(\d+)__END_EMAIL__/g, function(match, index) {
				return emails[index - 1];
			});
		}

		// <와 > 제거
		if(!isHTML || isHTML=='form') {
			returnTxt = returnTxt.replace(/</g, '').replace(/>/g, '')
						.replace(/&lt/gi, '').replace(/&gt/gi, '')
						;
		}
		
		//console.log('XSS :', typeof content, content, returnTxt);
		return returnTxt + orgPassword;
	} else if (typeof content == 'object') {
		$.each(content, function(key, value) {
			content[key]=xssfilter(value);
		});
		return content;
	} else {
		return content;
	}
};

// adobe
function getStepProductCode() {
	var code;
	if($('#modelSummary').length>0) code = $('#modelSummary').data('adobe-salesmodelcode');
	if(!code) code='';
	return code;
}
function changeTitleFormat(title) {
	// Please do not modify any spaces in the code below.
	return $.trim(title.toLowerCase().replace(/[^a-z0-9_ ]/gi, "")).replace(/ /gi, "_").replace(/_+/gi, "_");
}
function adobeSatellite(name, param) {
	console.log('adobe code : ' + name, param);
	if (typeof _satellite.track == 'function' && _dl) {
		_satellite.track(name, param);
	}
}
function adobeTrackEvent(name, param) {
	console.log('adobe code : ' + name, param);
	if (typeof _trackEvent == 'function' && _dl) {
		_trackEvent(
			$.extend(_dl, param)
		);
	}
}

function findModelName($obj) {
	var va = $obj.closest('.item').find('a.visual[data-adobe-modelname]').data('adobe-modelname'); // for product list
	if (!va) va = $obj.closest('.bundle').find('a[data-adobe-modelname]').data('adobe-modelname'); // for C0006
	if (!va) va = $obj.closest('.C0009').data('adobe-modelname'); // for C0009
	if (!va) va = $obj.closest('.C0017').data('adobe-modelname'); // for C0017
	if (!va) va = $obj.closest('.C0023').find('.model-info').data('adobe-modelname'); // for C0023
	if (!va) va = $obj.closest('.item').find('.img a[data-adobe-modelname]').data('adobe-modelname'); // for C0058
	if (!va) va = $obj.closest('li').find('.txt > a').data('adobe-modelname'); // for gnb search
	// for C0011
	if (!va && $obj.closest('.C0011').length>0){
		va = $('.C0009').data('adobe-modelname');
	}
	if (!va || va=="undefined") va = '';
	console.log('	adobe-modelName:', va);
	return va;
}

function findSalesModelCode($obj) {
	var va = $obj.closest('.item').find('a.visual[data-adobe-salesmodelcode]').data('adobe-salesmodelcode'); // for product list
	if (!va) va = $obj.closest('.bundle').find('a[data-adobe-salesmodelcode]').data('adobe-salesmodelcode'); // for C0006
	if (!va) va = $obj.closest('.C0009').data('adobe-salesmodelcode'); // for C0009
	if (!va) va = $obj.closest('.C0017').data('adobe-salesmodelcode'); // for C0017
	if (!va) va = $obj.closest('.C0023').find('.model-info').data('adobe-salesmodelcode'); // for C0023
	if (!va) va = $obj.closest('.item').find('.img a[data-adobe-salesmodelcode]').data('adobe-salesmodelcode'); // for C0058
	if (!va) va = $obj.closest('li').find('.txt > a').data('adobe-salesmodelcode'); // for gnb search
	// for C0011
	if (!va && $obj.closest('.C0011').length>0){
		va = $('.C0009').data('adobe-salesmodelcode');
	}
	if (!va || va=="undefined") va = '';
	console.log('	adobe-salesModelCode:', va);
	return va;
}

function findPrice($obj) {
	var va = $obj.closest('.item').find('.products-info .price-area .purchase-price .price').text(); // for product list, mylg > my product
	if (!va) va = $obj.closest('.bundle').find('.purchase-price .price').text(); // for C0006
	if (!va) va = $obj.closest('.pdp-info').find('.price-area .purchase-price .price').text(); // for pdp summary (C0009)

	if (!va) va = $obj.closest('.model-info').find('.selling-price').text(); // for C0023
	if (!va) va = $obj.closest('.item').find('.price-area .purchase-price .price').text(); // for C0058
	if (!va) va = $obj.closest('li').find('.hidden-price').text(); // for gnb search
	// for C0011
	if (!va && ($obj.closest('.C0011').length>0 || $obj.closest('.C0017').length>0)){
		va = $('.C0009').find('.pdp-info').find('.price-area .purchase-price').eq(0).find('.price').text();
	}
	if (!va || va=="undefined") va = '';
	va = va.replace(/[\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi, '');
	va = Number(va);
	console.log('	adobe-price:', va);
	return va;
}
function findModelCount($obj) {
	var va = 1;
	if($obj.closest('.C0010').length>0) num = $obj.closest('.C0010').find('.selected-items .selected-number .number').text();
	console.log('	adobe-count:', va);
	return va;
}
$('body').on('click', '.where-to-buy', function (event) {
    var $this = $(this);

    if ($this.data('clicked')) return;

    $this.data('clicked', true);
    $this.addClass('disabled').css('pointer-events', 'none');

	//LGEUS-766
	var $pdpWtb = $(this).closest('.C0009');
	if (!$pdpWtb.length) {
		adobeTrackEvent('where-to-buy', {
			products: [{sales_model_code : findSalesModelCode($(this)), model_name: findModelName($(this))}],
			page_event: {where_to_buy_click: true}
		});
	}
	if ($pdpWtb.length) {
		$('.C0011').find('a.where-to-buy').eq(0).click();
	}
	//LGEUS-766

});
$('body').on('click', '.inquiry-to-buy', function () {
	adobeTrackEvent('inquiry-to-buy', {
		products: [{sales_model_code : findSalesModelCode($(this)), model_name: findModelName($(this))}],
		page_event: {inquiry_to_buy_click: true}
	});
});
$('body').on('click', '.find-a-dealer', function () {
	adobeTrackEvent('find-a-dealer', {
		products: [{sales_model_code : findSalesModelCode($(this)), model_name: findModelName($(this))}],
		page_event: {find_a_dealer_click: true}
	});
});

// for responsive
var mql = {
	maxXs: window.matchMedia('(max-width: 575px)'),
	minSm: window.matchMedia('(min-width: 576px)'),
	sm: window.matchMedia('(min-width: 576px) and (max-width: 767px)'),
	maxSm: window.matchMedia('(max-width: 767px)'),
	minMd: window.matchMedia('(min-width: 768px)'),
	md: window.matchMedia('(min-width: 768px) and (max-width: 991px)'),
	maxMd: window.matchMedia('(max-width: 991px)'),
	minLg: window.matchMedia('(min-width: 992px)'),
	maxLg: window.matchMedia('(max-width: 1199px)'),
	lg: window.matchMedia('(min-width: 992px) and (max-width: 1199px)'),
	minXl: window.matchMedia('(min-width: 1200px)')
};
// Slick arrow html web
var carouselOptions = {
	squarePrev: '<button type="button" class="slick-prev type-square"><span class="visually-hidden">Previous</span></button>',
	squareNext: '<button type="button" class="slick-next type-square"><span class="visually-hidden">Next</span></button>',
	bigAnglePrev: '<button type="button" class="slick-prev"><span class="visually-hidden">Previous</span> <i class="icon"><svg width="23px" height="40px"><path fill-rule="evenodd" fill="currentColor" d="M21.577,2.477 L3.668,19.984 L21.577,37.491 C22.160,38.061 22.160,38.985 21.577,39.555 C20.994,40.126 20.048,40.126 19.465,39.555 L0.726,21.238 C0.634,21.181 0.534,21.140 0.454,21.061 C0.150,20.764 0.013,20.373 0.025,19.984 C0.013,19.595 0.150,19.204 0.454,18.907 C0.535,18.828 0.635,18.786 0.728,18.729 L19.465,0.412 C20.048,-0.158 20.994,-0.158 21.577,0.412 C22.160,0.983 22.160,1.908 21.577,2.477 Z"/></svg></i></button>',
	bigAngleNext: '<button type="button" class="slick-next"><span class="visually-hidden">Next</span> <i class="icon"><svg width="23px" height="40px"><path fill-rule="evenodd" fill="currentColor" d="M21.546,21.061 C21.466,21.140 21.366,21.181 21.274,21.238 L2.535,39.555 C1.952,40.126 1.006,40.126 0.423,39.555 C-0.161,38.985 -0.161,38.061 0.423,37.491 L18.332,19.984 L0.423,2.477 C-0.161,1.908 -0.161,0.983 0.423,0.412 C1.006,-0.158 1.952,-0.158 2.535,0.412 L21.272,18.729 C21.365,18.786 21.465,18.828 21.546,18.907 C21.849,19.204 21.987,19.595 21.975,19.984 C21.987,20.373 21.849,20.764 21.546,21.061 Z"/></svg></i></button>'
};
// Scripts that convert svg files to online-svg
var initSVG = function () {
	$('img.inline-svg').not('.lazyload').inlineSVG({
		beforeReplace: function ($img, $svg, next) {
			$svg.find("path").removeAttr("class").removeAttr("id").removeAttr("data-name");
			$svg.find("defs").remove();
			next();
		}
	});
};
// Get Cookie
var getCookie = function (name) {
	if ($.cookie(name)) {
		return decodeURIComponent($.cookie(name));
	} else {
		$.cookie(name);
	}
};
// Save Cookie
//LGEUS-12460
var setCookie = function (name, val, domainFlag, days) {
	var lh = location.host;
	var isProduction = lh === 'www.lg.com' || lh === 'members.us.lg.com';
	//var mydomain = isProduction ? '.lg.com' : '.us.lg.com';
	var mydomain = window.location.hostname;
	// if(lh.indexOf('lge.com')>=0) {
	// 	mydomain = '.lge.com';
	// }
	var domain = isProduction ? {} : { domain: mydomain };
	if (name=='LG5_B2B_CompareCart' || name=='LG5_CompareCart' || name == 'LG5_RecentlyView' || domainFlag == true) {
		domain = {
			path: '/',
			domain: mydomain
		};
	}
	//PJTGPFIVE-64
	if (name=='LG5_CartID' || name=='LG5_ZipCode') {
		domain = {
			path: '/us',
			domain: mydomain
		};
	}
	//PJTGPFIVE-64
	if(name=='LG5_SupportSearch' || name=='LG5_RememberAccount' || name=='LG5_RecentlyView' || name=='LG5_CST_RecentlyView') {
		domain.expires=30;
	}
	if(name=='Handraiser_Modal_Open'&&days){
		domain.expires=days;
	}
	$.cookie(name, encodeURIComponent(val), domain);
};
//LGEUS-12460
// Remove Cookie
var removeCookie = function (name, domainFlag) {
	var lh = location.host;
	var isProduction = lh === 'www.lg.com' || lh === 'members.us.lg.com';
	// var mydomain = isProduction ? '.lg.com' : '.us.lg.com';
	var mydomain = window.location.hostname;
	// if(lh.indexOf('lge.com')>=0) {
	// 	mydomain = '.lge.com';
	// }
	var domain = isProduction ?  {} : { domain: mydomain };
	if (name=='LG5_B2B_CompareCart' || name=='LG5_CompareCart' || name == 'LG5_RecentlyView' || domainFlag == true) {
		domain = {
			path: '/',
			domain: mydomain
		};
	}
	$.removeCookie(name, domain);
};
// pop-up
var winowPop = function () {
	var feedback = document.getElementsByClassName('lg-feedback');
	var hasMatchingUrl = location.pathname.includes('/us/support/contact')||location.pathname.includes('us/support/help-library');
	var isB2B = location.pathname.includes('/us/support/business')
	// DC-4544
	// Feedback event handler (Sticky button on the right side of the screen)
	$(feedback).on('click', function (e) {
		e.preventDefault();
		var target = this.getAttribute('href'),
		popupWidth = parseInt(this.getAttribute('data-width')),
		popupHeight = parseInt(this.getAttribute('data-height')),
		screenWidth = parseInt(screen.width),
		screenHeight = parseInt(screen.height),
		intLeft = Math.floor((screenWidth - popupWidth) / 2),
		intTop = Math.floor((screenHeight - popupHeight) / 2);

		if(intLeft < 0) intLeft = 0;
		if(intTop < 0 ) intTop = 0;
		window.open(target, '_blank', 'width=' + popupWidth + ',height=' + popupHeight + ',left='+intLeft + ',top=' + intTop + ',history=no,resizeable=no,status=no,scrollbars=yes,menubar=no');
	})

	var chatbot = document.querySelectorAll('[data-link-name=lg_chatbot]');
	var chatBotMinimized = false;
	// Chatbot Bubble Message
	if(!hasMatchingUrl && !isB2B) {
		setTimeout(function() {
			showBubble();
		}, 2000)
	}
	// Chatbot event handler (Sticky button on the bottom of the screen)
	$(chatbot).on('click', chatOpenClose);
	$(window).on("message", chatMinMax);

	function showBubble() {
		$('.bubble').animate({
			left: 0,
		},600);
		setTimeout(function() {
			$('.bubble').animate({
				left: 150
			},600)
		},4000)
	}

	function chatMinMax(e) {
		if(e.originalEvent.data.hideChatbot) {
			chatBotMinimized = true;
			$("#epromoter-chatbot-iframe").addClass("chatbot-minimized");
			$("#epromoter-chatbot-iframe").hide();
			var chatbot = document.querySelectorAll('[data-link-name=lg_chatbot]');
			$(chatbot).on("click", function minListener(event) {
				event.preventDefault();
				$("#epromoter-chatbot-iframe").removeClass("chatbot-minimized");
				$("#epromoter-chatbot-iframe").show();
				chatBotMinimized = false;
			});
		}
	}

	function chatOpenClose(e) {
		if(chatBotMinimized==false) {
			console.log("open close function:",e);
			e.preventDefault();
			var $iframe = '';
			if (this.search) {
				if(window.location.hostname == 'www.lg.com')
					$iframe = $('<iframe src="https://lg-chatbot.com/web/us'+this.search+'" id="epromoter-chatbot-iframe" class="epromotor-chatbot-area" allow="fullscreen;microphone;gelocation *"></iframe>');
				else
					$iframe = $('<iframe src="https://lge-global-chatbot-dev.uc.r.appspot.com/web/us'+this.search+'&debug=gsfs" id="epromoter-chatbot-iframe" class="epromotor-chatbot-area" allow="fullscreen;microphone;gelocation *"></iframe>');
			}
			else {
				if(window.location.hostname == 'www.lg.com')
					$iframe = $('<iframe src="https://lg-chatbot.com/web/us?locale=en_US" id="epromoter-chatbot-iframe" class="epromotor-chatbot-area" allow="fullscreen;microphone;gelocation *"></iframe>');
				else
					$iframe = $('<iframe src="https://lge-global-chatbot-dev.uc.r.appspot.com/web/us?locale=en_US&debug=gsfs" id="epromoter-chatbot-iframe" class="epromotor-chatbot-area" allow="fullscreen;microphone;gelocation *"></iframe>');

			}
			$iframe.on('load', function() {
				var $this = $(this);
				$this.addClass('ready');
				$this.addClass('active');
				window.addEventListener('message', function(event) {
					if(event.data === 'closeLGChatBot') {
						$this.remove();
					}
				}, false);
			});
			$('body').append($iframe);
		}
	}
};
// tooltip
var tooltipActive = function () {
	var tooltip = document.getElementsByClassName('js-tooltip');
	var tooltipClose = document.getElementsByClassName('tooltip-close');

	$(tooltip).on('click', function (e) {
		e.preventDefault();
		if ($(this).next('.tooltip-area').css('display') != 'block') {
			$('.tooltip-area').hide();
			$(this).next('.tooltip-area').show();
			// 화면 왼쪽 밖으로 나가는 경우 처리
			if(parseInt($(this).next('.tooltip-area').offset().left) < 0) {
				$(this).next('.tooltip-area').addClass('out');
			}
		} else {
			$(this).focus();
			$(this).next('.tooltip-area').hide();
		}
		
		/* PJTWAUS-1 : 20200113 add */
		var focusObj = $(this).next('.tooltip-area').find('a[href], area[href], input:not([disabled]), input:not([readonly]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]');
		var focusFirst = focusObj.first();
		var focusLast = focusObj.last();
		
		focusLast.on('keydown', function (e) {
			if ((e.which === 9 && !e.shiftKey)) {
				e.preventDefault();
				$(this).closest('.tooltip-wrap').find('.js-tooltip').focus();
				$(this).closest('.tooltip-area').hide();
			}
		});
		focusFirst.on('keydown', function (e) {
			if ((e.which === 9 && e.shiftKey)) {
				e.preventDefault();
				$(this).closest('.tooltip-wrap').find('.js-tooltip').focus();
				$(this).closest('.tooltip-area').hide();
			}
		});
		/*// PJTWAUS-1 : 20200113 add */
	});
	$(tooltipClose).on('click', function (e) {
		e.preventDefault();
		$(this).closest('.tooltip-wrap').find('.js-tooltip').focus();
		$(this).closest('.tooltip-area').hide();
	});
	$(window).resize(function () {
		$('.tooltip-area').hide();
	}).resize();
};
// count the number
var checkTextLength = function (obj, count) {
	var max = parseInt(obj.attr('maxlength'));
	/*
	obj.on('keyup, input', function () {
		var type = obj.val().length;
		var remain = max - type;
		count.text(remain);
		var str1 = obj.val();
		var str2 = "";
		if (remain <= 1) {
			str2 = str1.substr(0, max);
		}
	});
	*/
	obj.off('keyup input').on('keyup input', function (e) {
		var tgField = e.currentTarget;
		var byteTotal = 0;
		var tmpByte = 0;
		var strLen = 0;
		var c;
		for (var i = 0; i < tgField.value.length; i++) {
			c = escape(tgField.value.charAt(i));
			if (c.length == 1) {
				tmpByte++;
			} else if (c.indexOf("%u") != -1) {
				tmpByte += 2;
			} else {
				tmpByte++;
			}

			if (tmpByte > max) {
				strLen = i;
				break;
			} else {
				byteTotal = tmpByte;
			}
		}
		if (strLen) {
			tgField.value = tgField.value.substring(0, strLen);
		}
		count.text(Math.max(0, max-byteTotal));
		
		/* LGEUS-683 : 20200708 add */
		if(tgField.nodeName == 'TEXTAREA' && $(tgField).attr('aria-atomic')){
	        if(tmpByte%50 == 0){
	            $(tgField).attr('aria-atomic', true);
	        }else{
	            $(tgField).attr('aria-atomic', false);
	        }
	    }
		/*// LGEUS-683 : 20200708 add */
	});
};

// Script to prevent multiple calls of url once called in ajax call
var ajax = {
	cacheParams: [],
	cacheDatas: [],
	// default : cached data
	call: function (paramURL, param, type, callback, sType, progressiveTarget) {

		var dataType = type ? type : 'json';
		var sendType = sType ? sType : commonSendType;
		var pTarget = progressiveTarget;
		var isFormData = param instanceof FormData;

		var stringParam = param && param != null ? jQuery.param(param) : "";

		$.ajax({
			type: sendType,
			url: paramURL,
			// async: false,
			dataType: type,
			data: xssfilter(param),
			contentType: isFormData ? false : 'application/x-www-form-urlencoded; charset=UTF-8',
			processData: isFormData ? false : true,
			beforeSend: function () {
				// caching check;
				var idx = (sendType == 'post') ? ajax.cacheParams.indexOf(this.url + '?' + stringParam) : ajax.cacheParams.indexOf(this.url);
				if (idx >= 0) {
					data = ajax.cacheDatas[idx];
					callback(data, this);
					return false;
				}
				if (pTarget) {
					// console.log('loading', $(pTarget).attr('class'));
					$(pTarget).trigger('ajaxLoadBefore');
				}
			},
			success: function (d) {
				if (dataType == 'json') {
					if (typeof d === "string") {
						d = $.parsejson(d);
					}
				}
				if (d.status && d.status.toLowerCase() !== 'success' && d.message != null) { // error msg
					ajax.popupErrorMsg(d.message);
					d = false;
				} else { // pass data
					// check cached data
					if (sendType == 'post') ajax.cacheParams.push(this.url + '?' + stringParam);
					else ajax.cacheParams.push(this.url);

					// caching data
					ajax.cacheDatas.push(d);

				}
				// finish ajax loading
				if (pTarget) {
					$(pTarget).trigger('ajaxLoadEnd');
				}
				callback(d, this);
			},
			error: function (request, status, error) {
				console.log("status: " + status);
				console.log("error: " + error);
				if (pTarget) {
					$(pTarget).trigger('ajaxLoadEnd');
				}
				callback(false, this);
				ajax.popupErrorMsg(error);
			}
		});
	},
	// Scripts used when caching is disabled
	noCacheCall: function (paramURL, param, type, callback, sType, progressiveTarget) {
		var dataType = type ? type : 'json';
		var sendType = sType ? sType : commonSendType;
		var pTarget = progressiveTarget;
		var isFormData = param instanceof FormData;

		$.ajax({
			type: sendType,
			url: paramURL,
			// async: false,
			dataType: type,
			data: xssfilter(param),
			contentType: isFormData ? false : 'application/x-www-form-urlencoded; charset=UTF-8',
			processData: isFormData ? false : true,
			beforeSend: function () {
				if (pTarget) {
					// console.log('loading', $(pTarget).attr('class'));
					$(pTarget).trigger('ajaxLoadBefore');
				}
			},
			success: function (d) {
				if (dataType == 'json') {
					if (typeof d === "string") {
						d = $.parsejson(d);
					}
				}
				// error msg
				if (d.status != "success" && d.message != null) {
					ajax.popupErrorMsg(d.message);
					d = false;
				}

				if (pTarget) {
					$(pTarget).trigger('ajaxLoadEnd');
				}
				callback(d, this);
			},
			error: function (request, status, error) {
				console.log("status: " + status);
				console.log("error: " + error);
				if (pTarget) {
					$(pTarget).trigger('ajaxLoadEnd');
				}
				callback(false, this);
				ajax.popupErrorMsg(error);
			}
		});
	},
	popupErrorMsg: function (msg) {
		if(!$.trim(msg)) return false;
		console.log(msg);
		/*
		if (document.querySelectorAll('meta[name="v-mode"][content="author"]').length == 0) {
			var errorMsg = document.getElementById("serverErrorMsg");
			if (!errorMsg) {
				var html = '<div class="modal modal-simple fade" id="serverErrorMsg" tabindex="-1" role="dialog" data-backdrop="false"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph">' + msg + '</div></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" data-dismiss="modal">Close</button></div></div></div></div>';
				$('body').append(html);
				errorMsg = document.getElementById("serverErrorMsg");
				$(errorMsg).modal("show");
			} else if (!$(errorMsg).hasClass('show')) {
				errorMsg.querySelector('.content-paragraph').innerHTML = msg;
				$(errorMsg).modal("show");
			}
		}
		*/
	}
};
$('body').on({
	'ajaxLoadBefore':function(e){
		e.stopPropagation();
		$(this).append('<div class="loading-circle"><div class="lds-dual-ring"></div></div>');
	},
	'ajaxLoadEnd':function(e){
		e.stopPropagation();
		$(this).find('.loading-circle').remove();
	}
});
$('.C0003 .component-inner-box, .C0004 .component-inner-box, .C0064').on({
	'ajaxLoadBefore':function(e){
		e.stopPropagation();
		$(this).append('<div class="loading-circle"><div class="lds-dual-ring"></div></div>');
	},
	'ajaxLoadEnd':function(e){
		e.stopPropagation();
		$(this).find('.loading-circle').remove();
	}
});

// Script used to change options of select by calling ajax
(function ($) {
	$.fn.drawAjaxOptions = function (options) {
		var _this = this.get(0);
		var $this = $(this);
		var opt = $.extend({
			setTarget: null,
			url: null,
			dynamicParam: false,
			param: {},
			keyName: null,
			keyValue: null,
			empty: function () {
			},
			notEmpty: function () {
			},
			callback: function () {
			}
		}, options, $this.data());

		var category = {
			initialized: false,
			init: function () {
				if (!category.initialized) {
					category.addEvent();
					category.initialized = true;
				}
			},
			creatOptions: function (t, dataObj) {
				var html = '';
				// selectbox placeholder
				html += '<option value="" disabled selected>' + t.getAttribute('data-placeholder') + '</option>';

				if (opt.dataArray) {
					dataObj = dataObj.data instanceof Array ? dataObj.data[0] : dataObj;
					//console.log(dataObj);
					dataObj = dataObj[opt.dataArray];
				}
				if ((JSON.stringify(dataObj) == '{}' || dataObj.length == 0) || JSON.stringify(dataObj) == undefined) {
					$(t).html(html);
					t.setAttribute('disabled', 'disabled');
					$this.trigger('options.empty');
				} else {
					for (var key in dataObj) {
						var text, val;

						if (typeof dataObj[key] == "object") {
							if(opt.keyName != null && opt.keyValue != null) {
								// has option key names
								var currentKey = dataObj[key];
								text = currentKey[opt.keyName];
								val = currentKey[opt.keyValue];
							}else {
								// default
								for (var k in dataObj[key]) {
									text = dataObj[key][k];
									val = k;
								}
							}
							
						} else {
							text = dataObj[key],
								val = key; // jshint ignore:line
						}

						if (val == null || val == '') {
							html += '<option value="' + val + '" disabled>' + text + '</option>';
						} else {
							html += '<option value="' + val + '">' + text + '</option>';
						}
					}
					$(t).html(html);
					$this.trigger('options.notEmpty');
					t.removeAttribute('disabled');
				}
				$(t).trigger('chosen:updated');
			},
			changeEventFnc: function (e, f) {
				if(f == true) return false;
				var check = jQuery().checkValidation != undefined && $(e.currentTarget).checkValidation({onlyBoolean: true});
				if (e.currentTarget.type.indexOf("select") >= 0 || check) {
					var _param = opt.param;
					if (opt.dynamicParam == true) {
						_param = e.currentTarget.getAttribute('data-param');
						_param = _param ? _param : {};
					}

					if (typeof _param != 'string') {
						_param = $.param(_param);
					}

					var optionParam = $(e.currentTarget).find("option").eq(e.currentTarget.selectedIndex).data();
					optionParam = optionParam ? jQuery.param(optionParam) : "";

					var param = $(e.currentTarget).serialize() + "&" + _param + "&" + optionParam;
					var url = opt.url;
					// var data = ajax.call(url, param);
					ajax.noCacheCall(url, param, 'json', function (data) {
						if (data) {
							category.creatOptions(opt.setTarget, data);
							opt.callback(data);
						}
					});
					$(opt.setTarget).trigger('chosen:updated');
				}
			},
			addEvent: function () {
				if (_this.type.indexOf("select") >= 0) {
					$this.on({
						'change.base': category.changeEventFnc
					});
				} else {
					$this.on({
						'blur.base': category.changeEventFnc
					});
				}
				$this.on({
					'options.empty': opt.empty,
					'options.notEmpty': opt.notEmpty
				});
			}
		};

		return this.each(function () {
			category.init();
		});
	};
})(jQuery);

// form
var setForm = function () {
	var target = document.getElementsByTagName('form');
	for (var i = 0; i < target.length; i++) {
		var _this = target[i];
		var method = _this.getAttribute('data-ajax-method');
		method = method ? method : commonSendType;
		_this.removeAttribute('data-ajax-method');

		$(_this).data('ajaxMethod', method);
	}
};

// handraiserModalActive LGEUS-12460 START
var handraiserModalActive = function () {
	var categoryLevel = $('#categoryLevel').val();
	var modalOpenFlag = getCookie('Handraiser_Modal_Open');
	if($('.handraiser-signup-area').length){
		if(modalOpenFlag!='N'&&(window.location.pathname=='/us'||categoryLevel=='1')){
			setTimeout(function() {
				$('#handraiser-signup').modal('show');
			}, 3000);
		}
	}
};

$("#handraiser-signup .modal-dialog button[type='button']").click(function(){
	setCookie('Handraiser_Modal_Open','N',true,1);
});

$("#handraiserForm").submit(function(event) {
	event.preventDefault();
	var $form = $( this ),
	url = $form.attr( 'data-action' );
	if($('#EmailAddress').val() ==""){
		$('.handraiser-signup-area .error-msg').css('display', 'block');
		$('.handraiser-signup-area .error-msg .required').css('display', 'block');
		$('.handraiser-signup-area .error-msg .invalid').css('display', 'none');
	}else{
		var regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		if(!regex.test($('#EmailAddress').val())) {
			$('.handraiser-signup-area .error-msg').css('display', 'block');
			$('.handraiser-signup-area .error-msg .required').css('display', 'none');
			$('.handraiser-signup-area .error-msg .invalid').css('display', 'block');
		}else{
			var posting = $.post( url, { EmailAddress: $('#EmailAddress').val(),CMPID: window.location.href } );
			$('.form-section').css('display', 'none');
			$('.thankyou-section').css('display', 'block');
			setCookie('Handraiser_Modal_Open','N',true);
			_satellite.track("formSubmit");
		}
	}
});
//MNT-874
//Remove  seo section if text is blank.
var copyRemove = function() {
	$(".footer-caution:contains('\u3164')").parent().hide();
}

//ENT-1446
$(".footer-caution-toggle").on("click", function() {     
    $(this).parent().children('.disclaimer-toggle-text').toggle("slow");
	$(this).text() == "Show Less" ? $(this).text("Show More") : $(this).text("Show Less");
});
//END ENT-1446


//LGEUS-12460 END
// init
$(document).ready(function () {
	//DC-1213 STARTED
	//Tab management for track repair page
	var queryString = window.location.href.split("#");
	if (queryString.length > 1) {
		for (query in queryString) {
			var thisQuery = queryString[query];
			if (thisQuery.length > 1 && thisQuery == 'repairService') {
				$(".track-repair-signin .tab-inner .btn-tab[href='#repairService']").trigger("click");
			} else if (thisQuery.length > 1 && thisQuery == 'mobileProducts') {
				$(".track-repair-signin .tab-inner .btn-tab[href='#mobileProducts']").trigger("click");
			} else {
				console.log("Proceed without query");
			}
		}
	}
	//DC-1213 END

	initSVG();
	winowPop();
	tooltipActive();
	setForm();
	//LGEUS-12460
	handraiserModalActive();
	//MNT-874
	copyRemove();
});

function coveoSearchEvent(data, searchTxt, token, url) {
	var isLoggedIn = $('.login.logged').length > 0;
	var visitordId = getCookie('COVEO_VISITOR_ID') || '';

	var data;	
	if (visitordId) {
		data = JSON.stringify({
			"anonymous": !isLoggedIn,
			"language": data.language,
			"originLevel1": data.originLevel1,
			"actionCause": 'searchboxAsYouType',
			"queryText": searchTxt,
			"responseTime": 0,
			"searchQueryUid": data.searchQueryUid,
			"clientId": visitordId,
			"userAgent":navigator.userAgent,
			"numberOfResults":data.totalCount,

		});
		}
		else
		{
			data = JSON.stringify({
			"anonymous": !isLoggedIn,
			"language": data.language,
			"originLevel1": data.originLevel1,
			"actionCause": 'searchboxAsYouType',
			"queryText": searchTxt,
			"responseTime": 0,
			"searchQueryUid": data.searchQueryUid,
			"userAgent":navigator.userAgent,
			"numberOfResults":data.totalCount,

		});
	}

	$.ajax({
		type: "POST",
		url: url,
		data: data,
		dataType: 'json',
		headers: {
			'Authorization': token,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': true,
			"Access-Control-Allow-Headers" : 'Content-Type',
			"Access-Control-Allow-Methods": 'POST',
		},
		success: function(data) {
			if (!visitordId) {
				setCookie('COVEO_VISITOR_ID', data.visitorId);
			}
		},
		error: function(e) {
			console.error('Coveo Error: ', e);
		}
	});
}

function coveoClickEvent($element, token, url, shouldRedirect, productUrl, isSearchModal) {
	var href = productUrl || $element.attr('href');
	var isLoggedIn = $('.login.logged').length > 0;
	var nonRedirects = [
		'/us/support/repair-service/schedule-repair-continued',
		'/us/support/partner-portal/request-repair', 
		'/us/support/business/request-ra-service',
		'/us/support/business/request-swap-service',
		'/us/support/contact/email-appointment',
		'/us/support/contact/braille-request',
		'/us/mylg/product-registration',
		'/us/support/repair-service/schedule-repair-continued-mylg'
	];
	var willRedirect = !!href && ((shouldRedirect && !nonRedirects.includes(window.location.pathname)) || isSearchModal);
	var data = {
		"anonymous": !isLoggedIn,
		"actionCause": $element.data('action-cause'),
		"documentPosition": $element.data('document-position'),
		"documentTitle": $element.data('document-title'),
		"documentUri": $element.data('document-uri'),
		"documentUriHash": $element.data('document-uri-hash'),
		"documentUrl": $element.data('document-url'),
		"language": $element.data('language'),
		"originLevel1": $element.data('origin-level'),
		"searchQueryUid": $element.data('search-query-uid'),
		"sourceName" : $element.data('source-name'),
		"clientId": getCookie('COVEO_VISITOR_ID') || '',
		"userAgent":navigator.userAgent,
		"rankingModifier":$element.data('ranking-modifier'),
	};
	
	if (!data.rankingModifier){
		delete data.rankingModifier;
	}
	
	data= JSON.stringify(data);

	$.ajax({
		type: "POST",
		url: url,
		data: data,
		dataType: 'json',
		headers: {
			'Authorization': token,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': true,
			"Access-Control-Allow-Headers" : 'Content-Type',
			"Access-Control-Allow-Methods": 'POST',
		},
		success: function(data) {
			if (willRedirect) {
				window.location.href = href;
			}
		},
		error: function(e) {
			console.error('Coveo Error: ', e);
			if (willRedirect) {
				window.location.href = href;
			}
		}
	});
}

function debounce(func) {
	var timer;
	return function(args) {
		clearTimeout(timer);
		timer = setTimeout(function() { 
			func.apply(this, args); 
		}, 400);
	};
}

function escapeQuote(str) {
    return str.replace('"', '&quot;');
}


// search box common script
var searchCommon = [],
	searchInit = function () {
		$('.search-common').each(function (idx) {
			var $searchCommon = $(this);
			this.setAttribute('data-search-idx', idx);
			searchCommon[idx] = {
				//$obj : $(document.querySelector('.search-common')),
				$obj: $searchCommon,
				canFocus: 0,
				canSubmit: 0,
				canCookie: 0,
				minLength: 0,
				cookieName: '',
				action: '',
				max: 10,
				functionName: '',
				$layer: null,
				$input: null,
				$recentArea: null,
				$resultArea: null,
				$template: null,
				$btnSubmit: '',
				$btnClose: '',
				init: function () {
					var el = this.$obj;
					this.canFocus = el.data('canfocus');
					this.canSubmit = el.data('cansubmit');
					this.cookieName = el.data('cookiename');
					this.functionName = el.data('function');
					// TODO: In future minLength needs to come from backend
					// this.minLength = el.data('minlength') ? el.data('minlength') : 1;
					this.minLength = 2;
					this.$layer = el.find('.search-layer');
					this.$input = el.find('.search-common-input');
					this.$recentArea = this.$layer.find('.recent-suggested-type');
					this.$resultArea = this.$layer.find('.search-result-list');
					this.$template = this.$layer.find('template');
					this.action = el.attr('action');
					this.max = el.data('max');
					this.$btnSubmit = el.find('input.submit');
					this.$btnClose = el.find('.link-close');
					if (this.cookieName) {
						this.canCookie = 1;
					}
					// Recent, Focus and Input must be run when layer exists
					if (this.$layer.length > 0) {
						this.bindInput();
						if (this.canFocus > 0) {
							this.bindFocus();
						} else {
							// hide see more
							this.$resultArea.find('.search-result-seemore').hide();
							this.bindNoFocus();
						}
						// If layer exists in mobile, float to top in focus
						/*
						if ('ontouchstart' in window) {
							this.$input.on('focus', function() {
								//searchCommon[idx].$obj.addClass('fixed');
								$('body').addClass('floating-search');
							});
							// close
							this.$input.on('blur', function() {
								searchCommon[idx].$obj.removeClass('fixed');
								$('body').removeClass('band-scroll');
							});
						}
						*/
						// Find all focusable children

						var focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

						if(!$('.navigation').hasClass('mobile-device')) { // do not remove 'if', because android bug
							this.$input.on('blur', function(e) {
								e.preventDefault();
								var close = $(this).closest('.search-common').find('.search-layer .predictive-search:visible').find('.search-footer-area a.link-close');
								if(close && close.length>0) {
									close.focus();
								}
							});
						}

						this.$layer.on({
							keydown: function(e){
								// Convert NodeList to Array
								var focusableElements = e.currentTarget.querySelectorAll(focusableElementsString);
								focusableElements = Array.prototype.slice.call(focusableElements);
								var firstTabStop = focusableElements[0];
								var lastTabStop = focusableElements[focusableElements.length - 1];
								// Check for TAB key press
								if (e.keyCode === 9) {
									// SHIFT + TAB
									if (e.shiftKey) {
										if (document.activeElement === firstTabStop) {
											e.preventDefault();
											lastTabStop.focus();
										}
									// TAB
									} else {
										if (document.activeElement === lastTabStop) {
											e.preventDefault();
											firstTabStop.focus();
										}
									}
								}
								// ESCAPE
								if (e.keyCode === 27) {
									closeModal();
								}
							}
						}, '.predictive-search');
					}
					// this.$btnClose.on('click', function (e) {
					// 	e.preventDefault();
					// 	searchCommon[idx].$layer.removeClass('active');
					// 	if(searchCommon[idx].$btnSubmit.length>0) searchCommon[idx].$btnSubmit.focus();
					// });
					/* PJTWAUS-1 20200315 modify */
					this.$btnClose.on('click', function (e) {
						e.preventDefault();
						searchCommon[idx].$layer.removeClass('active').addClass('closed-layer');
						if(searchCommon[idx].$input.length>0) {
							searchCommon[idx].$input.focus();
						}
					});
					/* // PJTWAUS-1 20200315 modify */

					// Submit
					this.bindSubmit();

					// Close search layer
					$('body').on('click touchend', function (event) {
						if (!$(event.target).parents('.search-area')[0]) {
							searchCommon[idx].$layer.removeClass('active');
						}
					});
					// Close the search layer when blur event occurs at the end of the search layer
					this.$recentArea.find('ul.list:last-child li:last-child a').on('blur', function () {
						searchCommon[idx].$layer.removeClass('active');
						console.log('');
					});
					this.$resultArea.find('.search-result-seemore a').on('blur', function () {
						searchCommon[idx].$layer.removeClass('active');
					});

					// init
					this.$input.data('oldtext', ''); // for ie11 bug
				},
				bindNoFocus: function () {
					if($('.search-model-result').length>0) {
						// manuals and documents, software and drivers page only
						this.$input.on('focus', function (e) {
							e.preventDefault();

							/* PJTWAUS-1 20200315 modify */
							if (!searchCommon[idx].$layer.hasClass('active') && !searchCommon[idx].$layer.hasClass('closed-layer') ) {
								searchCommon[idx].$layer.addClass('active');
							}
							/* // PJTWAUS-1 20200315 modify */

							/* PJTWAUS-6 : 20200310  add */
							$(this).on('keydown', function(f) {
								// Check for TAB key press
								if (f.keyCode === 9) {
									// SHIFT + TAB
									if (f.shiftKey) {
										if (searchCommon[idx].$layer.hasClass('active')) {
											e.preventDefault();
											searchCommon[idx].$layer.removeClass('active');
										}
									}
								}
							});
							/* // PJTWAUS-6 : 20200310  add */
						});
						/* PJTWAUS-1 20200315 modify */
						this.$input.on('blur', function (e) {
							e.preventDefault();
							searchCommon[idx].$layer.removeClass('closed-layer');
						});
						/* // PJTWAUS-1 20200315 modify */
					}
				},
				bindFocus: function () {
					this.$input.on('focus', function (e) {
						e.preventDefault();
						// check the cookie
						var recentCookieTxt;
						var recentCookieArr;
						if (this.canCookie == 1) {
							recentCookieTxt = getCookie(searchCommon[idx].cookieName);
							if (recentCookieTxt == undefined) recentCookieTxt = '';
							recentCookieArr = recentCookieTxt.split('|');
							if (recentCookieTxt == '') {
								removeCookie(searchCommon[idx].cookieName);
							}
						}
						
						// open the layer
						/* PJTWAUS-1 20200315 modify */
						if (!searchCommon[idx].$layer.hasClass('active') && !searchCommon[idx].$layer.hasClass('closed-layer') ) {
							searchCommon[idx].$layer.addClass('active');
						}
						/* // PJTWAUS-1 20200315 modify */

						/* PJTWAUS-6 : 20200310  add */
						$(this).on('keydown', function(f) {
							// Check for TAB key press
							if (f.keyCode === 9) {
								// SHIFT + TAB
								if (f.shiftKey) {
									if (searchCommon[idx].$layer.hasClass('active')) {
										e.preventDefault();
										searchCommon[idx].$layer.removeClass('active');
									}
								}
							}
						});
						/* // PJTWAUS-6 : 20200310  add */
						
						searchCommon[idx].doRecent();
					});
					/* PJTWAUS-1 20200315 modify */
					this.$input.on('blur', function (e) {
						e.preventDefault();
						searchCommon[idx].$layer.removeClass('closed-layer');
					});
					/* // PJTWAUS-1 20200315 modify */
				},
				bindInput: function () {
					this.$input.on('input', debounce(function (e) {
						// check the cookie
						var recentCookieTxt;
						var recentCookieArr;
						if (this.canCookie == 1) {
							recentCookieTxt = getCookie(searchCommon[idx].cookieName);
							recentCookieArr = recentCookieTxt.split('|');
							if (recentCookieTxt == '') {
								removeCookie(searchCommon[idx].cookieName);
							}
						}
						// check the input's value
						if (searchCommon[idx].$input.val() == '' && (searchCommon[idx].$input.data('oldtext') != searchCommon[idx].$input.val())) {
							searchCommon[idx].doRecent();
							// open the layer
							if (!searchCommon[idx].$layer.hasClass('active')) {
								searchCommon[idx].$layer.addClass('active');
							}
						} else if (searchCommon[idx].minLength <= searchCommon[idx].$input.val().length) {
							searchCommon[idx].doResult();
							// open the layer
							if (!searchCommon[idx].$layer.hasClass('active')) {
								searchCommon[idx].$layer.addClass('active');
							}
						}
						searchCommon[idx].$input.data('oldtext', searchCommon[idx].$input.val());
					}));
				},
				bindSubmit: function () {
					this.$btnSubmit.on('click', function (e) {
						e.preventDefault();
						if ($(this).closest('.parts-accessories').length > 0) {
							// for parts & accessories
							adobeTrackEvent('parts-accessories-search', {
								search_keyword: $(this).siblings('.search-common-input').val(),
								search_type: "support:parts_accessories",
								page_event: {onsite_search: true}
							});
						}
						searchCommon[idx].doSubmit();
					});
				},
				bindClickKeyword: function () {
					searchCommon[idx].$recentArea.find('ul.list li a').not('.delete').off('click', '**').on('click', function (e) {
						e.preventDefault();
						// Adding a value to the search input and Submit Form
						var searchTxt = xssfilter($(this).find('.product-name').text(), 'form');
						searchCommon[idx].$input.val(searchTxt);
						searchCommon[idx].doSubmit();
					});
				},
				bindDeleteKeyword: function () {
					searchCommon[idx].$recentArea.find('ul.list li a.delete').off('click', '**').on('click', function (e) {
						e.preventDefault();
						var searchTxt = xssfilter($(this).parent().find('.product-name').text(), 'form'),
							recentNoResult = searchCommon[idx].$recentArea.find('.not-result'),
							recentList = searchCommon[idx].$recentArea.find('ul.list.recent-keyword');
						//console.log('test1');
						if (searchCommon[idx].canCookie == 1) {
							//console.log('test2');
							searchCommon[idx].deleteCookieList(searchTxt);
						}
						// Remove this in the list
						$(this).closest('li').remove();
						if (searchCommon[idx].$recentArea.find('ul.list.recent-keyword li').length <= 0) {
							recentNoResult.show();
							recentList.empty().hide();
						}
					});
				},
				doSubmit: function (noRefresh) {
					var searchTxt = xssfilter(this.$input.val(), 'form');
					this.$input.val(searchTxt);
					if (!this.$obj.is('.auto-validation-form')) {
						if ($.trim(searchTxt) != '') {
							if (this.canCookie == 1) this.addCookieList(searchTxt);
							var noSubmitArea = this.$resultArea.find('.no-submit');
							if (this.canSubmit == 1 && noRefresh != true) {
								if($('.resource-search-form-wrap').length>0 && $('.results-summary').length>0) {
									// CS Help Library, CS Video Tutorials
									adobeTrackEvent('cs-onsite-search', {search_keyword : searchCommon[idx].$obj.find('.search-input input[type=text]').val(), page_event : {onsite_search : true}});
								}
								this.$obj.submit();
							} else {
								noSubmitArea.show();
								if($('.search-model-result').length>0) {
									// manuals and documents, software and drivers page only
									if (this.canFocus <= 0) {
										if (!searchCommon[idx].$layer.hasClass('active')) {
											searchCommon[idx].$layer.addClass('active');
										}
									}
								}
								return false;
							}
						} else {
							return false;
						}
					} else {
						this.$obj.submit();
					}
				},
				doResult: function () {
					this.$resultArea.show();
					this.$recentArea.hide();
					var noSubmitArea = this.$resultArea.find('.no-submit'),
						url = this.$input.data('predictive-url'),
						searchTxt = {};

					if(this.$resultArea.closest('.C0009').length>0) {
						searchTxt.searchModel = xssfilter(this.$input.val(), 'form');
						searchTxt.modelId=this.$obj.find('input[name=modelId]').val();
					} else {
						searchTxt.search = xssfilter(this.$input.val(), 'form');
					}

					noSubmitArea.hide();
					// ajax call
					ajax.call(url, searchTxt, 'json', function (data, _this) {
						if (!!searchTxt.search && searchTxt.search.toLowerCase() === 'bcp2a') {
							var item = {
								linkText: '0',
								resultText: '0',
								predictive: [
									{
										actionCause: "documentOpen",
										analyticsClick: "https://5fh0343mba.execute-api.us-east-1.amazonaws.com/v1/ua/v15/analytics/click",
										analyticsSearch: "https://5fh0343mba.execute-api.us-east-1.amazonaws.com/v1/ua/v15/analytics/search",
										anonymous: true,
										category: "",
										cs_category_id: "",
										cs_sub_category_id: "",
										documentPosition: 1,
										documentTitle: "BCP2A.AUSALA",
										documentUri: "https://www.lg.com/us/support/repair-service/BCP2A.AUSALLA_FULL_ENG_2210.pdf",
										documentUriHash: "",
										documentUrl: "https://www.lg.com/us/support/repair-service/BCP2A.AUSALLA_FULL_ENG_2210.pdf",
										language: "en",
										model: "BCP2A",
										name: "LG Ultrasonic Cleanser",
										originLevel1: "LGRepair",
										queryText: "BCP2A",
										rankingModifier: "",
										searchQueryUid: "",
										sourceName: "Repair",
										token: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJ2OCI6dHJ1ZSwib3JnYW5pemF0aW9uIjoibGdlbGVjdHJvbmljc3VzYW5vbnByb2R1Y3Rpb24xajVnZ2pqamgiLCJ1c2VySWRzIjpbeyJwcm92aWRlciI6IkVtYWlsIFNlY3VyaXR5IFByb3ZpZGVyIiwibmFtZSI6Imluc2VydHlvdXJlbWFpbGhlcmVAeW91cmRvbWFpbi5jb20iLCJ0eXBlIjoiVXNlciJ9XSwicm9sZXMiOlsicXVlcnlFeGVjdXRvciJdLCJleHAiOjE2Njg2OTQwOTAsImlhdCI6MTY2ODYwNzY5MH0.M8RAgEioqU8-W7wOAyqWHJr8XNMSul4-6qUGqCEtPEg",
										totalCount: "0",
										url: "/us/support/repair-service/BCP2A.AUSALLA_FULL_ENG_2210.pdf",
									}
								],
							};
							searchCommon[idx].ajaxSuccess(item, _this);
						} else if (data.data) {
							searchCommon[idx].ajaxSuccess(data.data[0], _this);
						} else {
							searchCommon[idx].ajaxSuccess(data, _this);
						}
					});
				},
				doRecent: function () {
					if (this.canFocus == 1) this.$recentArea.show();
					this.$resultArea.hide();
					// check the cookie
					var recentCookieTxt;
					var recentCookieArr;
					if (this.canCookie == 1) {
						recentCookieTxt = getCookie(this.cookieName);
						if (recentCookieTxt == undefined) recentCookieTxt = '';
						recentCookieArr = recentCookieTxt.split('|');
						if (recentCookieTxt == '') {
							removeCookie(this.cookieName);
						}
						var recentNoResult = searchCommon[idx].$recentArea.find('.not-result'),
							recentList = searchCommon[idx].$recentArea.find('ul.list.recent-keyword');
						//console.log(recentCookieArr);
						if (recentCookieTxt == 'undefined' || recentCookieTxt == '') {
							recentNoResult.show();
							recentList.empty().hide();
						} else {
							if (recentCookieTxt == '') {
								recentNoResult.show();
								recentList.empty().hide();
							} else {
								var list = '';
								for (var i = 0; i < recentCookieArr.length; i++) {
									list = list + '<li><a href="#"><strong class="product-name">' + recentCookieArr[i] + '</strong></a><a href="#" class="delete"><span class="icon"></span><span class="sr-only">Delete</span></a></li>';
								}
								recentNoResult.hide();
								recentList.html(list).show();
								this.bindDeleteKeyword();
							}
						}
						this.bindClickKeyword();
						// show recent head
						this.$recentArea.find('.search-head').eq(0).show();
					} else {
						searchCommon[idx].$recentArea.find('.not-result').hide();
						searchCommon[idx].$recentArea.find('ul.list.recent-keyword').empty().hide();
						// hide recent head
						this.$recentArea.find('.search-head').eq(0).hide();
					}
				},
				addCookieList: function (searchTxt) {
					// add searchTxt in cookie list
					var recentCookieTxt = getCookie(this.cookieName);
					if (recentCookieTxt == undefined) recentCookieTxt = '';
					var recentCookieArr = recentCookieTxt.split('|');

					// Clear duplicate values on array
					var isDup = recentCookieArr.indexOf(searchTxt);
					if (isDup > -1) recentCookieArr.splice(isDup, 1);

					// If you have five search terms, delete the oldest one.
					if(recentCookieArr.length>=5) {
						recentCookieArr.pop();
					}

					// Add new value to the front of the array
					if (recentCookieTxt == 'undefined' || recentCookieTxt == '') recentCookieArr = [searchTxt];
					else recentCookieArr.unshift(searchTxt);

					// set Cookie
					setCookie(this.cookieName, recentCookieArr.join('|'));
				},
				deleteCookieList: function (searchTxt) {
					// delete searchTxt in cookie list
					var recentCookieTxt = getCookie(this.cookieName);
					if (recentCookieTxt == undefined) recentCookieTxt = '';
					var recentCookieArr = recentCookieTxt.split('|');
					// delete searchTxt from array
					var isTxt = recentCookieArr.indexOf(searchTxt);
					if (isTxt > -1) recentCookieArr.splice(isTxt, 1);

					// set Cookie
					if (recentCookieArr.length > 0) {
						setCookie(this.cookieName, recentCookieArr.join('|'));
					} else {
						removeCookie(this.cookieName);
					}
				},
				ajaxSuccess: function (data, _this) {
					var seemore = searchCommon[idx].$resultArea.find('.search-result-seemore');
					if (!data.link || data.link == '') {
						seemore.hide();
					} else {
						seemore.show().find('a').attr('href', data.link);
					}

					var recentNoResult = searchCommon[idx].$resultArea.find('.not-result'),
						resultList = searchCommon[idx].$resultArea.find('ul.list');

					if (data.Flag == 'N') {
						recentNoResult.show();
						resultList.hide();
					} else {
						var coveoToken;
						var coveoSearchUrl;
						var coveoClickUrl;
						var isCoveoSearch = window.location.href.includes('/us/support') || window.location.href.includes('/mylg/product-registration') || window.location.href.includes('/us/search');
						
						var searchTxt = xssfilter(this.$input.val(), 'form');
						if (data && data.predictive[0] && data.predictive[0].token && isCoveoSearch) {
							coveoToken = data.predictive[0].token;
							coveoSearchUrl = data.predictive[0].analyticsSearch;
							coveoClickUrl = data.predictive[0].analyticsClick;
							coveoSearchEvent(data.predictive[0], searchTxt, coveoToken, coveoSearchUrl);
						}
						
						recentNoResult.hide();
						resultList.show();
						var len = data.predictive ? data.predictive.length : '';
						html = '';
						if (len > 0) {
							if (len > this.max) len = this.max;

							if (this.$template[0] != undefined) {
								var template = this.$template;
								this.$template.find('script').remove();
								for (var j = 0; j < len; j++) {
									var _templateMarkup = template.clone().html();
									_templateMarkup = _templateMarkup.replace('*url*', data.predictive[j].url)
										.replace('*model*', data.predictive[j].model)
										.replace('*name*', data.predictive[j].name)
										.replace('*category*', data.predictive[j].category);

									html = html + (_templateMarkup);
								}

							} else {
								for (var i = 0; i < len; i++) {
									var tempCateHTML = '';
									if(data.predictive[i].cate) tempCateHTML = tempCateHTML + ' data-category='+data.predictive[i].cate;
									else if(data.predictive[i].cs_category_id) tempCateHTML = tempCateHTML + ' data-category='+data.predictive[i].cs_category_id;
									if(data.predictive[i].subcate) tempCateHTML = tempCateHTML + ' data-subcategory='+data.predictive[i].subcate;
									else if(data.predictive[i].cs_sub_category_id) tempCateHTML = tempCateHTML + ' data-subcategory='+data.predictive[i].cs_sub_category_id;

									if (coveoToken && isCoveoSearch) {
										tempCateHTML = tempCateHTML + ' data-action-cause='+data.predictive[i].actionCause;
										tempCateHTML = tempCateHTML + ' data-document-position='+data.predictive[i].documentPosition;
										//tempCateHTML = tempCateHTML + ' data-document-title='+data.predictive[i].documentTitle;
										tempCateHTML = tempCateHTML + ' data-document-title="'+escapeQuote(data.predictive[i].documentTitle)+'"';
										tempCateHTML = tempCateHTML + ' data-document-uri='+data.predictive[i].documentUri;
										tempCateHTML = tempCateHTML + ' data-document-uri-hash='+data.predictive[i].documentUriHash;
										tempCateHTML = tempCateHTML + ' data-document-url='+data.predictive[i].documentUrl;
										tempCateHTML = tempCateHTML + ' data-language='+(data.predictive[i].language || 'en');
										//tempCateHTML = tempCateHTML + ' data-origin-level='+data.predictive[i].originLevel1;
										tempCateHTML = tempCateHTML + ' data-origin-level="'+escapeQuote(data.predictive[i].originLevel1)+'"';
										tempCateHTML = tempCateHTML + ' data-search-query-uid='+data.predictive[i].searchQueryUid;
										tempCateHTML = tempCateHTML + ' data-source-name="'+escapeQuote(data.predictive[i].sourceName)+'"';
										tempCateHTML = tempCateHTML + ' data-modalid='+data.predictive[i].modalId;
										if(data.predictive[i].rankingModifier)
										{
										tempCateHTML = tempCateHTML + ' data-ranking-modifier="'+escapeQuote(data.predictive[i].rankingModifier)+'"';
										}
										
									} 
									
									
										if(window.location.href.includes('/us/support') || window.location.href.includes('/mylg/product-registration')){
											
											if (data.predictive[i].model) { // model search
												
												html = html + '<li><a rel="nofollow" href="' + data.predictive[i].url + '"'+tempCateHTML+'><span class="model-name">' + data.predictive[i].model + '</span><span class="product-name">' + data.predictive[i].name + '</span><span class="category-name">' + data.predictive[i].category + '</span></a></li>';
												
											}
											else {
												
												html = html + '<li><a rel="nofollow" href="' + data.predictive[i].url + '"'+tempCateHTML+'><span class="model-name">' + data.predictive[i].content + '</span><span class="product-name">' + data.predictive[i].name + '</span><span class="category-name">' + data.predictive[i].category + '</span></a></li>';
											}
										}else if(window.location.href.includes('/us/search')){
											
											if (data.predictive[i].model) { // model search
												
												html = html + '<li><a rel="nofollow" href="' + data.predictive[i].url + '"'+tempCateHTML+'><span class="product-name">' + data.predictive[i].name + '</span><span class="category-name">' + data.predictive[i].category + '</span></a></li>';
												
											}
											else {
												
												html = html + '<li><a rel="nofollow" href="' + data.predictive[i].url + '"'+tempCateHTML+'><span class="product-name">' + data.predictive[i].name + '</span><span class="category-name">' + data.predictive[i].category + '</span></a></li>';
											}
										}									
									
								}
							}
							resultList.html(html);
							if (this.functionName && this.functionName != '') {
								resultList.find('li a').on('click', function (e) {
									e.preventDefault();
									var model = $(this).find('.model-name').text();
									var category = $(this).data('category');
									var subcategory = $(this).data('subcategory');
									var functionNameText = searchCommon[idx].functionName;
									if(functionNameText) {
										if(functionNameText=="pickerBox.model") {
											// CS Software & Drivers, CS Manuals & Documents
											new Function(functionNameText + '("' + model + '", "'+category+'", "'+subcategory+'")')(); // jshint ignore:line
											$(this).closest('form.search-common').find('.search-input input[type=text]').val($(this).find('.model-name').text());
											// CS Software & Drivers, CS Manuals & Documents : Search 
											adobeTrackEvent('cs-onsite-search', {search_keyword : searchCommon[idx].$obj.find('.search-input input[type=text]').val(), page_event : {onsite_search : true}});
										} else {
											new Function(functionNameText + '()')(); // jshint ignore:line
										}
									}
									searchCommon[idx].$layer.removeClass('active');

									if (isCoveoSearch) {
										coveoClickEvent($(this), coveoToken, coveoClickUrl, false);
									}
								});
							} else if (isCoveoSearch) {
								resultList.find('li a').on('click', function (e) {
									e.preventDefault();
									coveoClickEvent($(this), coveoToken, coveoClickUrl, true);
								});
							}
							var $resultValue = searchCommon[idx].$layer.find('.results .value');
							if ($resultValue.length > 0) {
								$resultValue.text(data.predictive.length);
							}
						} else {
							recentNoResult.show();
							resultList.hide();
							var $resultValue1 = searchCommon[idx].$layer.find('.results .value');
							if ($resultValue1.length > 0) {
								$resultValue1.text('0');
							}
						}
					}
				}
			};
			searchCommon[idx].init();
		});
	};
(function ($) {
	if (!document.querySelector('.search-common')) return false;
	searchInit();
})(jQuery);

// jquery datepicker 
(function ($) {
	var runDatepicker = function () {
		var dps = document.querySelectorAll('.datepicker-wrap');

		$(dps).each(function () {
			var el = this; // this
			var $calander = $(el).find('.run-datepicker'); // this
			var dayNames = $calander.get(0).getAttribute('data-day-names');
			if (dayNames) {
				var split = dayNames.match(/,|\/|-|\|/);
				dayNames = dayNames.match(/,|\/|-|\|/) ? dayNames.split(split) : null;
			}
			$calander.datepicker({
				dayNamesMin: dayNames ? dayNames : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], // default = 영문
				showOtherMonths: true,
				dateFormat: $calander.get(0).getAttribute('data-date-format'),
				changeYear: true,  
				yearRange: "1920:2025",  
				beforeShow: function () {
					if (!('ontouchstart' in window)) {
						$(window).on({
							resize: function () {
								$('.run-datepicker').datepicker('hide').blur();
								$(window).off('resize');
							}
						});
					}
				}
			});
			// .datepicker("setDate", new Date())
			$(el).find('.datepicker-trigger').on({
				click: function (e) {
					e.preventDefault();
					$calander.datepicker('show');
				}
			});
		});
	};
	runDatepicker();
})(jQuery);

// tab Scripts
(function ($) {
	if (!document.querySelector('.tabs-type-liner') && !document.querySelector('.tabs-type-rect')) return false;
	var $tabs = $('.tabs-type-liner, .tabs-type-rect');
	$tabs.each(function () {

		// all tabs Web accessibility
		if($tabs.is('[role="tablist"]')){			
			$('[role="tab"]').attr('tabindex', -1);
			if($('[role="tab"]').has('active')){
				setTimeout(function(){ 
					$('[role="tab"].active').attr('tabindex', 0);
				}, 500);
			}
			$('[role="tabpanel"]').attr('tabindex', 0);
		} 


		// key control
		$(this).find('a').on('keydown', function(e){			
			var keycode = e.keyCode
			var keys = {
				left: 37,
				right: 39,
			};
			var direction = {
				37: -1,
				39: 1,
			};
			var keyProceed = false;
			var index = $(e.target).parents('[role="tablist"]').find('[role="tab"]').index(this);
			if(keycode === keys.right){
				e.preventDefault();
				e.stopPropagation();
				keyProceed = true;
			} else if( keycode === keys.left){
				e.preventDefault();
				e.stopPropagation();
				keyProceed = true;
			}
			if (keyProceed) {						
				targetTab(e);
			} 
			function targetTab(e){
				e.preventDefault();
				var keyProceed = e.keyCode;
				var target = e.target;
				var targetTab = $(target).parents('[role="tablist"]').find('[role="tab"]').length;
				var targetActive = $(target).parents('[role="tablist"]').find('[role="tab"]');	
				if($(target).parents('[role="tablist"]').length !== 0){
					if (direction[keyProceed]) {
						if(targetActive[index + direction[keyProceed]]){

							e.stopPropagation();

							targetActive[index + direction[keyProceed]].focus();
						} else if (keyProceed === keys.left) {
							targetActive[0].focus();
						} else if (keyProceed === keys.right) {
							targetActive[targetTab - 1].focus();
						};
					}		
				} else{
					console.log("not Accessibility Guide")
					//접근성 키보드 관련해서 wai 구조상 값이 잘못되면 나타남 해당 console시 tab 유형을 확인하세요
				}													
			}
		});
		// end
		$(this).find('a').on('click', function (e) {
			// click tabs
			e.preventDefault();
			if ($(this).attr('href').indexOf('/') != -1) {
				location.href = $(this).attr('href');
			} else {
				var target = '#' + $(this).attr('href').split('#')[1];
				var $parent = $(this).closest('.tabs-type-liner, .tabs-type-rect');
				// change tab design
				// LGEGMO-5525 add 
				$parent.find('a').not($(this)).removeClass('active').attr({'aria-selected' : false, 'tabindex' : '-1'});
				$(this).addClass('active').attr({'aria-selected' : true, 'tabindex' : '0'});
				// LGEGMO-5525 add 
				// toggle selected tab area
				if (target != "#" && $(target).get(0)) {
					var tclass = $(target).attr('class').replace(' active', '').split(' ')[0];
					//console.log(tclass);
					var target2 = ($parent.parent().find('.' + tclass).length > 0) ? $parent.parent().find('.' + tclass) : $parent.parent().parent().find('.' + tclass);
					if (target2.length == 0 && $parent.closest('.tab-wrap').length > 0) {
						var $parent2 = $parent.closest('.tab-wrap');
						target2 = ($parent2.parent().find('.' + tclass).length > 0) ? $parent2.parent().find('.' + tclass) : $parent2.parent().parent().find('.' + tclass);
					}
					//console.log(target2);
					var parentType = 1;
					if ($parent.closest('.track-repair-signin').length > 0) {
						// track repair signin
						target2 = $parent.closest('.track-repair-signin').find('.' + tclass);
						parentType = 2;
					} else if ($parent.closest('.component').length > 0) {
						// components (all)
						parentType = 3;
						target2 = $parent.closest('.component').find('.' + tclass);
						if ($(target).find('.slick-slider').length > 0) {
							setTimeout(function () {
								$(target).css({"opacity": 0});
								target2.removeClass('active');
								$(target).addClass('active');
								setTimeout(function () {
									$(target).find('.slick-slider').css({'width': '100%'}).slick('setPosition');
									$(target).animate({'opacity': 1}, 200);
								}, 200);
							}, 200);
						} else {
							target2.removeClass('active');
							$(target).addClass('active');
						}
					}
					if (parentType < 3) {
						target2.removeClass('active');
						$(target).addClass('active');
					}
				}
			}
		});
	});
})(jQuery);

// tab Scripts
(function ($) {
	//radiogroup
	if (!document.querySelector('.model-group') && !document.querySelector('.color-list') && !document.querySelector('.size-list')) return false;
	var $radioTab = $('.model-group, .color-list, .size-list');
	$radioTab.each(function () {	
		$(this).find('a').on('keydown', function(e){
			var keycode = e.keyCode
			var keys = {
				left: 37,
				right: 39,
			};
			var direction = {
				37: -1,
				39: 1,
			};
			var keyProceed = false;
			var index = $(e.target).parents('[role="radiogroup"]').find('[role="radio"]').index(this);
			if(keycode === keys.right){
				e.stopPropagation();
				keyProceed = true;
			} else if( keycode === keys.left){
				e.stopPropagation();
				keyProceed = true;
			}
			if (keyProceed) {
				targetTab(e);
			}
			function targetTab(e){
				e.preventDefault();
				var keyProceed = e.keyCode;
				var target = e.target;
				var targetTab = $(target).parents('[role="radiogroup"]').find('[role="radio"]').length;
				var targetActive = $(target).parents('[role="radiogroup"]').find('[role="radio"]');
				if($(target).parents('[role="radiogroup"]').length !== 0){
					if (direction[keyProceed]) {
						if(targetActive[index + direction[keyProceed]]){
							targetActive[index + direction[keyProceed]].focus();
						} else if (keyProceed === keys.left) {
							targetActive[0].focus();
						} else if (keyProceed === keys.right) {
							targetActive[targetTab - 1].focus();
						};
					}
				} else{
					console.log("not Accessibility Guide")
					//접근성 키보드 관련해서 wai 구조상 값이 잘못되면 나타남 해당 console시 radiogroup 유형을 확인하세요.
				}
			}
		});
	});
})(jQuery);

// Scripts that run on designed input=file
var bindFileUpload;
(function ($) {
	bindFileUpload = function () {
		var $file = $('.delivery-part').not('.available');
		if ($file.length >= 1) {
			$file.each(function () {
				var _this = this;
				$(this).addClass('available');
				var $inputTxt = $(this).find('.file-name-expose'),
					$inputFile = $(this).find('.replace-file-input input[type=file]');

				// Print file name when file is attached
				$(_this).on({
					change: function (e) {
						var fileName;
						var delTarget = e.delegateTarget;
						var _$inputTxt = $(delTarget).find('input.file-name-expose');
						if(e.currentTarget.files[0]) {
							var deleteText = _$inputTxt.attr('data-delete-title') ? _$inputTxt.attr('data-delete-title') : 'Delete';
							if (window.FileReader) {
								fileName = $(e.currentTarget)[0].files[0].name;
							} else {
								fileName = $(e.currentTarget).val().split('/').pop().split('\\').pop();
							}
							_$inputTxt.focus().val(fileName);

							$(delTarget).addClass('attached');

							if (!$(delTarget).find('div.file-name-expose').get(0)) {
								_$inputTxt.wrap('<div class="file-name-expose"></div>');
								$(delTarget).find('div.file-name-expose').append('<a class="delete" href="#"><span class="icon"></span><span class="sr-only">' + xssfilter(deleteText) + '</span></a>');
							}
						}else {
							fileName = $(e.currentTarget).val().split('/').pop().split('\\').pop();
							$(e.currentTarget).closest('.field-block').removeClass('error');
							_$inputTxt.focus().val('');
						}
					}
				}, 'input[type=file]');

				$(_this).on({
					click: function (e) {
						e.preventDefault();
						var delTarget = e.delegateTarget;
						$(delTarget).removeClass('attached');
						$(delTarget).find('input').val('');

						// Remove error class when file is deleted
						$(delTarget).closest('.error').removeClass('error');
					}
				}, '.delete');

				// Pre-treat capacity limits to prevent arbitrary changes
				if ($inputFile.data('max')) {
					var max = $inputFile.data('max');
					$inputFile.removeAttr('data-max');
					$inputFile.data('max', max);
				}

				// Pre-process file extension limit values to prevent arbitrary changes
				if ($inputFile.data('extension')) {
					var extension = $inputFile.data('extension');
					$inputFile.removeAttr('data-extension');
					$inputFile.data('extension', extension);
				}
			});
		}
	};
	bindFileUpload();
})(jQuery);

// modal
$(function () {
	// fix modal for ie9
	var isIE = window.ActiveXObject || "ActiveXObject" in window;
	if (isIE) {
		$('.modal').removeClass('fade');
	}
});

// print
var runPrint;
(function ($) {
	runPrint = function () {
		if (!document.querySelector('.page-print') && !document.querySelector('.page-print')) return false;
		var $printPage = $('.page-print');
		$printPage.off().on('click', function (e) {
			e.preventDefault();

			if($('.request-repair-completion').length>0) {
				// request repair 
				adobeTrackEvent('cs-repair-print', {page_event : {print_repair_request : true}});
			} else if($('.dispatch-portal-completion').length>0) {
				// dispatch portal
				adobeTrackEvent('cs-repair-print', {page_event : {print_repair_request : true}});
			} else if($('.request-ra-completion').length>0) {
				// request ra
				adobeTrackEvent('cs-repair-print', {page_event : {print_repair_request : true}});
			} else if($('.request-swap-completion').length>0) {
				// request swap
				adobeTrackEvent('cs-repair-print', {page_event : {print_repair_request : true}});
			} else if ($('.repair-info-wrap').length>0) {
				// track repair detail
				adobeTrackEvent('cs-repair-print', {page_event : {print_repair_request : true}});
			} else if ($('.email-result').length>0) {
				// email result 
				adobeTrackEvent('cs-repair-print', {page_event : {print_repair_request : true}});
			}

			var modal = $(e.currentTarget).parents('.modal').get(0);
			if (modal) {
				//console.log('1');
				var divToPrint = modal;
				var newWin = window.open('', 'Print-Window');
				newWin.document.open();
				newWin.document.write('<html><body onload="window.print()"><link rel="stylesheet" href="/lg5-common/css/modal-print.min.css" type="text/css" /><div class="modal">' + divToPrint.innerHTML + '</div></body></html>');
				setTimeout(function() {
					newWin.document.close();
					setTimeout(function () {
						newWin.close();
					}, 10);
				}, 200);
			} else {
				window.print();
			}
		});
	};
	runPrint();
})(jQuery);

// scroll tab
//LGEGMO-5525 modify
var tabMktControll;
(function ($) {
	// WA-Common-Tab : mobile용 tab scroll guide 삭제
	// tabGuideActive = function () {
	// 	var $guide = $('.js-tab-guide-outer');
	// 	for (var i = 0; i < $guide.length; i++) {
	// 		var $this = $guide.eq(i);
	// 		var arrow = '<div class="arrow"></div>';
	// 		if ($this.outerWidth() < $this.find('.js-tab-guide-inner').outerWidth()) {
	// 			$this.append(arrow);
	// 		}
	// 	}
	// 	$guide.off().on({
	// 		touchend: function () {
	// 			$(this).find('.arrow').addClass('js-fade');
	// 		}
	// 	});
	// };
	// if ('ontouchstart' in window && mql.maxXs.matches) {
	// 	$(window).on({
	// 		'orientationchange.tab': function () {
	// 			tabGuideActive();
	// 		}
	// 	});
	// 	tabGuideActive();
	// }

	tabMktControll = function () {
		// WA-Common-Tab : tab scroll button 추가를 위한 CSS 수정;
		var $mktTab = $('.js-tab-controll, .js-tab-controll-type2');
		$mktTab.on({
			click: function (e) {
				var current = e.currentTarget,
					scroll = e.delegateTarget.querySelector('.js-tab-guide-outer');
				// .mCustomScrollbar('scrollTo','+=300');
				// this.mcs.topPct
				if ($(current).is('.scroll-left')) {
					$(scroll).mCustomScrollbar('scrollTo', '+=300');
				} else if ($(current).is('.scroll-right')) {
					$(scroll).mCustomScrollbar('scrollTo', '-=300');
				}
			}
		}, 'button');
		/* PJTWAUS-1 : 20200121 modify */
		$mktTab.find('.js-tab-guide-outer').on({
			scrolled: function () {
				var $wrap = $(this).closest('.js-tab-controll, .js-tab-controll-type2'); // WA-Common-Tab
				$wrap.find('.scroll-left').removeAttr('disabled').attr('aria-disabled', false); 
				$wrap.find('.scroll-right').removeAttr('disabled').attr('aria-disabled', false);
			},
			totalScroll: function () {
				var $wrap = $(this).closest('.js-tab-controll, .js-tab-controll-type2'); // WA-Common-Tab
				$wrap.find('.scroll-left').removeAttr('disabled').attr('aria-disabled', false); 
				$wrap.find('.scroll-right').attr('disabled', 'disabled').attr('aria-disabled', true); 
			},
			totalScrollBack: function () {
				var $wrap = $(this).closest('.js-tab-controll, .js-tab-controll-type2'); // WA-Common-Tab
				$wrap.find('.scroll-left').attr('disabled', 'disabled').attr('aria-disabled', true); 
				$wrap.find('.scroll-right').removeAttr('disabled').attr('aria-disabled', false); 
			}
		});
		/*// PJTWAUS-1 : 20200121 modify */
		$mktTab.find('.js-tab-guide-outer').trigger('totalScrollBack');
	};
	// WA-Common-Tab : 모바일에서도 tabMktControll 활성화되도록 수정
	// if (!('ontouchstart' in window && mql.maxXs.matches)) {
	// 	tabMktControll();
	// }
	tabMktControll();
	// //js-tab-controll
})(jQuery);
// LGEGMO-5525 modify

// ie browser form attribute polyfill
(function () {
	// Via Modernizr
	function formAttributeSupport() {
		var form = document.createElement("form"),
			input = document.createElement("input"),
			div = document.createElement("div"),
			id = "formtest" + (new Date().getTime()),
			attr,
			bool = false;

		form.id = id;
		// IE6/7 confuses the form idl attribute and the form content attribute
		if (document.createAttribute) {
			attr = document.createAttribute("form");
			attr.nodeValue = id;
			input.setAttributeNode(attr);
			div.appendChild(form);
			div.appendChild(input);

			document.documentElement.appendChild(div);

			bool = form.elements.length === 1 && input.form == form;

			div.parentNode.removeChild(div);
		}

		return bool;
	}

	if (!formAttributeSupport()) {
		$(document)
			.on("click", "[type=submit][form]", function (event) {
				event.preventDefault();
				var formId = $(this).attr("form"),
					$form = $("#" + formId).submit();
			})
			.on("keypress", "form input", function (event) {
				var $form;
				if (event.keyCode == 13) {
					$form = $(this).parents("form");
					if ($form.find("[type=submit]").length == 0 &&
						$("[type=submit][form=" + $(this).attr("form") + "]").length > 0) {
						$form.submit();
					}
				}
			});
	}
}());

function loginCheck() {
	var $nav = $('.navigation');
	var $columnRight = $('.column-right'); // ENT-601
	var $loadingE = $('request-repair-intro-wrap .loading');
	var isMultishop = ['eos', 'pm'].includes($nav.data('store')); // DC-1661
	if ($nav.length > 0) {
		var loginURL = $nav.eq(0).data('login-check');
		if (loginURL) {
			var token = getCookie('ACCESS_TOKEN');
			if(token) {
				var scrollToTop = function ($obj) {
					var t = $obj.closest('.navigation').offset().top;
					//console.log(t);
					$('html, body').stop().animate({scrollTop: t}, 300);
				};
				ajax.call(loginURL, 'access_token='+token, 'json', function (data) {
					var loginFlag = false;
					if (data.login && data.login.status == 'active') {
						loginFlag = true;
					}
					if (loginFlag) {
						//LGEITF-989
						var firstname = maskingMsg(data.context.user.first_name);
						var lastname = maskingMsg(data.context.user.last_name);
						var $loggedInIconText = $('<span>' + data.context.user.first_name.charAt(0) + data.context.user.last_name.charAt(0) + '</span>'); // DC-1661
						//LGEITF-989
						//LGEUS-188 Start
						var userId = data.context.user.email;
						var loginState = false;
						if(userId != ''){
							loginState = true;
							if(typeof standardVariable == 'undefined'){
								standardVariable = { userId : '', loginState : false};
							}
							standardVariable.userId = SHA256(userId);
							standardVariable.loginState = loginState;
						}
						//LGEUS-188 End
						// desktop
						$nav.find('.for-desktop .right-btm .login').addClass('logged').find('.after-login .welcome .name').text(firstname + ' ' + lastname);
						// mobile
						$nav.find('.for-mobile .menu .mylg .login').addClass('logged').find('.after-login .name').text(firstname + ' ' + lastname);
						$nav.find('.for-mobile .menu .mylg ').next().find('.welcome .name').text(firstname + ' ' + lastname);
						$nav.find('.for-mobile .nav-wrap .right .login').addClass('logged'); //LGEGMC-777 add
						$columnRight.find('.login').addClass('logged'); // ENT-601

						// Temp fix for critical issue
						var $afterLoginClone = $nav.find('.for-desktop .after-login').clone();
						var welcome = $nav.hasClass('b2b') ? 'welcomeB2B': 'welcomeB2C';
						$afterLoginClone.addClass('sublayer-m').removeClass('after-login').attr('id', welcome);
						var $ul = $afterLoginClone.find('ul');
						$ul.find('li').addClass('type2 section1');
						$ul.find('li:first-child a').css('padding-top', '12px');
						$afterLoginClone.append($('<ul class="depth2-m"><li class="type2 section1"></li></ul>'));
						$afterLoginClone.find('.depth2-m > li').append($ul);
						$nav.find('.for-mobile .menu .menu-wrap').append($afterLoginClone);

						// DC-1661
						if (!isMultishop) {
							$nav.find('.login.logged > a').prepend($loggedInIconText); // DC-1661
						}
						
						// bind click event
						$nav.find('.for-mobile .menu .mylg .login.logged,.for-mobile .nav-wrap .right .login.logged').find('>a.after-login').on('click', function (e) {
							e.preventDefault();
							/*LGEGMC-777*/
							if($(this).parents(".right").length){
								$nav.find('.for-mobile .menu>a').trigger("click");
							}
							/*//LGEGMC-777*/
							var targetID = $(this).attr('href').split('#')[1];
							if (targetID && targetID.length > 0) {
								var $target = $(this).closest('.navigation').find('#' + targetID);
								var $mobileNav = $nav.find('.for-mobile');
								var $mobileDepth1 = $mobileNav.find('.depth1-m');
								var $mobileTopMenu = $mobileNav.find('.top-link');
								var $mobileMyLG = $mobileNav.find('.mylg');
								$target.toggleClass('active');

								$mobileDepth1.removeClass('active');
								$mobileTopMenu.removeClass('active');
								$mobileMyLG.removeClass('active');
								/*LGEGMC-777*/
								if($(this).parents(".right").length){
									$target.find(".back").hide();
								}else{
									$target.find(".back").show();
								}
								/*//LGEGMC-777*/
								scrollToTop($(this));
							}
						});
						// page url ? LoginFlag=Y
						var currentUrl = window.location.href;
						if(currentUrl.indexOf('LoginFlag=Y') != -1) {
							//data tags
							adobeSatellite('set_member_id', {'member_id' : data.context.user.id});
							
							if(history.replaceState) {
								history.replaceState({login:'login'}, '', currentUrl.replace('?LoginFlag=Y', '').replace('&LoginFlag=Y', ''));
							}
						}
					}
					// for obs menu
					if (data.obsLoginFlag == "N") {
						//console.log($nav.find('.obs-menu'));
						$nav.find('.obs-menu').remove();
					}
				}, 'get');
			}
		}
	}
	$('.request-repair-intro-wrap .loading').addClass("d-none");
    $('.request-repair-intro-wrap .column-right').removeClass("d-none");

}

//LGEITF-989
function maskingMsg(msg){
	var returnMsg = '';
	if(null!=msg && ''!=msg){
		var length = Math.floor(msg.length/2);
		for(var i=0;i<msg.length;i++) {
			if(i >= length){
				returnMsg = returnMsg.concat('*');
			}else{
				returnMsg = returnMsg.concat(msg.charAt(i));
			}
		}
	}
	return returnMsg;
}
//LGEITF-989

//LGEUS-188 Sha 256 변환 함수 
function SHA256(s){
      
    var chrsz   = 8;
    var hexcase = 0;
  
    function safe_add (x, y) {
        var lsw = (x & 0xFFFF) + (y & 0xFFFF);
        var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        return (msw << 16) | (lsw & 0xFFFF);
    }
      
    function S (X, n) { return ( X >>> n ) | (X << (32 - n)); }
    function R (X, n) { return ( X >>> n ); }
    function Ch(x, y, z) { return ((x & y) ^ ((~x) & z)); }
    function Maj(x, y, z) { return ((x & y) ^ (x & z) ^ (y & z)); }
    function Sigma0256(x) { return (S(x, 2) ^ S(x, 13) ^ S(x, 22)); }
    function Sigma1256(x) { return (S(x, 6) ^ S(x, 11) ^ S(x, 25)); }
    function Gamma0256(x) { return (S(x, 7) ^ S(x, 18) ^ R(x, 3)); }
    function Gamma1256(x) { return (S(x, 17) ^ S(x, 19) ^ R(x, 10)); }
      
    function core_sha256 (m, l) {
             
        var K = new Array(0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5, 0x3956C25B, 0x59F111F1,
            0x923F82A4, 0xAB1C5ED5, 0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3,
            0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174, 0xE49B69C1, 0xEFBE4786,
            0xFC19DC6, 0x240CA1CC, 0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA,
            0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147,
            0x6CA6351, 0x14292967, 0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13,
            0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85, 0xA2BFE8A1, 0xA81A664B,
            0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070,
            0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A,
            0x5B9CCA4F, 0x682E6FF3, 0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208,
            0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2);
 
        var HASH = new Array(0x6A09E667, 0xBB67AE85, 0x3C6EF372, 0xA54FF53A, 0x510E527F, 
                   0x9B05688C, 0x1F83D9AB, 0x5BE0CD19);
 
        var W = new Array(64);
        var a, b, c, d, e, f, g, h, i, j;
        var T1, T2;
  
        m[l >> 5] |= 0x80 << (24 - l % 32);
        m[((l + 64 >> 9) << 4) + 15] = l;
      
        for ( var i = 0; i<m.length; i+=16 ) {
            a = HASH[0];
            b = HASH[1];
            c = HASH[2];
            d = HASH[3];
            e = HASH[4];
            f = HASH[5];
            g = HASH[6];
            h = HASH[7];
  
            for ( var j = 0; j<64; j++) {
                if (j < 16) W[j] = m[j + i];
                else W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);
  
                T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
                T2 = safe_add(Sigma0256(a), Maj(a, b, c));
  
                h = g;
                g = f;
                f = e;
                e = safe_add(d, T1);
                d = c;
                c = b;
                b = a;
                a = safe_add(T1, T2);
            }
  
            HASH[0] = safe_add(a, HASH[0]);
            HASH[1] = safe_add(b, HASH[1]);
            HASH[2] = safe_add(c, HASH[2]);
            HASH[3] = safe_add(d, HASH[3]);
            HASH[4] = safe_add(e, HASH[4]);
            HASH[5] = safe_add(f, HASH[5]);
            HASH[6] = safe_add(g, HASH[6]);
            HASH[7] = safe_add(h, HASH[7]);
        }
        return HASH;
    }
      
    function str2binb (str) {
        var bin = Array();
        var mask = (1 << chrsz) - 1;
        for(var i = 0; i < str.length * chrsz; i += chrsz) {
            bin[i>>5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i%32);
        }
        return bin;
    }
      
    function Utf8Encode(string) {
        string = string.replace(/\r\n/g,"\n");
        var utftext = "";
  
        for (var n = 0; n < string.length; n++) {
  
            var c = string.charCodeAt(n);
  
            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
  
        }
  
        return utftext;
    }
      
    function binb2hex (binarray) {
        var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
        var str = "";
        for(var i = 0; i < binarray.length * 4; i++) {
            str += hex_tab.charAt((binarray[i>>2] >> ((3 - i%4)*8+4)) & 0xF) +
            hex_tab.charAt((binarray[i>>2] >> ((3 - i%4)*8  )) & 0xF);
        }
        return str;
    }
      
    s = Utf8Encode(s);
    return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
      
}
//LGEUS-188

function loginCheckSupport() {
	var $nav = $('.navigation');
	var isPmStore = $nav.data('store') === 'pm';
	if ($nav.length > 0) {
		var loginURL = $nav.eq(0).data('login-check');
		if (loginURL) {
			var token = getCookie('ACCESS_TOKEN');
			if(token) {
				ajax.call(loginURL, 'access_token='+token, 'json', function (data) {
					var loginFlag = false;
					if (data.login && data.login.status == 'active') {
						loginFlag = true;
					}
					if (loginFlag) {
						//LGEITF-989
						var respFirstName = data.context.user ? data.context.user.first_name : data.firstname;
						var respLastName = data.context.user ? data.context.user.last_name : data.lastname;
						var firstname = maskingMsg(respFirstName);
						var lastname = maskingMsg(respLastName);
						var $loggedInIconText = $('<span>' + respFirstName.charAt(0) + respLastName.charAt(0) + '</span>'); 
						// desktop
						$nav.find('.for-desktop .right-btm .login').addClass('logged').find('.after-login .welcome .name').text(firstname + ' ' + lastname);
						// mobile
						$nav.find('.for-mobile .menu .mylg .login').addClass('logged').find('.after-login .name').text(firstname + ' ' + lastname);
						$nav.find('.for-mobile .menu .mylg ').next().find('.welcome .name').text(firstname + ' ' + lastname);
						$nav.find('.for-mobile .nav-wrap .right .login').addClass('logged'); //LGEGMC-777 add

						// Temp fix for critical issue
						var $afterLoginClone = $nav.find('.for-desktop .after-login').clone();
						var welcome = $nav.hasClass('b2b') ? 'welcomeB2B': 'welcomeB2C';
						$afterLoginClone.addClass('sublayer-m').removeClass('after-login').attr('id', welcome);
						var $ul = $afterLoginClone.find('ul');
						$ul.find('li').addClass('type2 section1');
						$ul.find('li:first-child a').css('padding-top', '12px');
						$afterLoginClone.append($('<ul class="depth2-m"><li class="type2 section1"></li></ul>'));
						$afterLoginClone.find('.depth2-m > li').append($ul);
						$nav.find('.for-mobile .menu .menu-wrap').append($afterLoginClone);

						// DC-1661
						if (!isPmStore) {
							$nav.find('.login.logged > a').prepend($loggedInIconText); // DC-1661
						}
						
						var currentUrl = window.location.href;
						if(currentUrl.indexOf('LoginFlag=Y') != -1) {
							//data tags
							adobeSatellite('set_member_id', {'member_id' : data.context.user ? data.context.user.id : '' });
							
							if(history.replaceState) {
								history.replaceState({login:'login'}, '', currentUrl.replace('?LoginFlag=Y', '').replace('&LoginFlag=Y', ''));
							}
						}
					}
					// for obs menu
					if (data.obsLoginFlag == "N") {
						//console.log($nav.find('.obs-menu'));
						$nav.find('.obs-menu').remove();
					}

				}, 'get');
			}
		}
	}
}

// navigation
(function () {
	if (!document.querySelector('.navigation')) return false;
    setTimeout( function() {
		$('.carousel-wrap .slick-prev').attr('aria-label', 'Left Arrow');
		$('.carousel-wrap .slick-next').attr('aria-label', 'Right Arrow');
		$('.items .slick-prev').attr('aria-label', 'Left Arrow');
		$('.items .slick-next').attr('aria-label', 'Right Arrow');
	    $('.list-contents-wrap .slick-prev').attr('aria-label', 'Left Arrow');
		$('.list-contents-wrap .slick-next').attr('aria-label', 'Right Arrow');
	},3000);
	// navigation
	var $nav = $('.navigation');
	if ($nav.length > 0) {

		// desktop view
		// gnb
		var $gnbLogos = $nav.find('.menu-wrap .top-link a, .for-desktop .left-top a'); 
		var $depthLink = $nav.find('.depth1 > li > a, .depth2 li > a');
		var $scrollBtn = $nav.find('.depth1 .scroll .scroll-left a, .depth1 .scroll .scroll-right a');
		var $icons = $nav.find('.right-btm .icons, .right .icons, .left .icons');
		var $iconBtn = $icons.find('>div>a');
		var $searchInput = $nav.find('.search .search-input input.input');
		var $closeSubLayer = $('.sublayer .close a', $nav);
		var $searchForms = $nav.find('.gnb-search-form');
		var $holidayPromotionBanner = $nav.find('#promotionHeader');
		var isMouseOver = false;

		var $cartLinks = $('a[href*="/checkout/cart"]');
		$cartLinks.each(function() {
			var zipCode = getCookie('LG5_ZipCode');
			if (!!zipCode) {
				var href = $(this).attr('href');
				var newHref;
				if (href.includes('?cartId=')) {
					newHref = href + '&zipCode=' + zipCode;
				} else {
					newHref = href + '?zipCode=' + zipCode;
				}
				$(this).attr('href', newHref);
			}
		});

		// DC-1661 Changes start
		var isMultishop = ['eos', 'pm'].includes($nav.data('store'));
		var $mobileNav = $nav.find('.for-mobile');
		// Shop button (or Product solutions button on B2B)
		var $shopBtn = $depthLink.filter('[data-link-name=Shop],[data-link-name~=Products]');
		$shopBtn.attr('href', '#');
		// Product Support button
		var $productSupportButton = $depthLink.filter(function() {
			return $(this).parent().parent().hasClass('depth2');
		}).eq(1);
		// "X" icon for closing sublayer
		var $close =  $('.sublayer .close');

		// DC-1661 Add underline for menu animation
		var $underline = $('<span></span>').addClass('sublayer-target');
		$('body').append($underline);

		$close.on('click', function() {
			if (!isMultishop) {
				if ($underline.hasClass('active')) {
					$underline.removeClass('active');
				}
			}
		});

		function onSuperCategoryCategorSelect(e) {
			e.preventDefault();
			var selectedTabId = $(this).data('tab-name');
			if (!isMultishop) {
				var tabSelector = $(this).parents('.tablet-layer').length ? '.tablet-layer ' : '';
				var $tabs = $nav.find(tabSelector + '.tab-content');
				
				if ($tabs.find(selectedTabId).hasClass('active') && e.type !== 'mouseenter') {
					window.location.href = $(this).attr('href');
				}

				$tabs.find('.tab-pane.active').removeClass('active');
				$tabs.find(selectedTabId).addClass('active');
				
				var $el = $(this).parent()[0];
				var width = $el.getBoundingClientRect().width;
				var height = $el.getBoundingClientRect().height;
				var left = $el.getBoundingClientRect().left + window.pageXOffset;
				var top = '204px';

				if ($holidayPromotionBanner.is(':visible')) {
					top = '264px';
				}

				if ($('#joinUsBanner').is(':visible')) {
					top = '654px';
				}

				if ($('.support-gallery').is(':visible')) {
					top = '257px';
				}

				$underline.css({
					width: width + 'px',
					height: height + 'px',
					left: left + 'px',
					top: top,
					borderColor: '#000',
					transform: 'none',
					zIndex: 10001,
				});
			}
		}

		// DC-1661 super category tabs show menu on hover
		$('ul.nav.nav-pills.row li a').on({
			mouseenter: onSuperCategoryCategorSelect,
			click: onSuperCategoryCategorSelect,
			touchend: onSuperCategoryCategorSelect,
		});

		// Temp change for product support mobile link
		$('#gnbproductsupportM').find('.depth2-m li.type1:first-child').removeClass('type1').addClass('type2').addClass('section1');

		// DC-1661 Changes End

		// DC-1789 Tagging for logo buttons
		$gnbLogos.on('click', function() {
			var label = $(this).find('img').attr('alt');
			dataLayer.push({
				event: 'mainNavigation',
				category: 'main navigation',
				action: 'logo click',
				label: label,
				businessUnit: _dl.page_name.bu,
				componentID: '',
			});
		});

		if ($holidayPromotionBanner.length && getCookie('Holiday_Banner_Open') !== 'N') {
			$holidayPromotionBanner.css('display', 'flex');

			var $holidayCloseIcon = $holidayPromotionBanner.find('#holidayPromoClose');

			$holidayCloseIcon.on('click', function() {
				$holidayPromotionBanner.hide();

				setCookie('Holiday_Banner_Open', 'N', true, 1);
			});

			if (navigator.userAgent.includes('Macintosh')) {
				$holidayPromotionBanner.find('.text').css({
					paddingTop: '5px',
				});

				$holidayPromotionBanner.find('.btn.btn-primary').css({
					paddingTop: '10px',
				});
			}
		}

		$searchForms.on('submit', function(e) {
			var searchTxt = $(this).find('.search-input input[type=text]').val();
			$(this).find('.search-input input[type=text]').val(xssfilter(searchTxt, 'form'));
			if(searchTxt!=xssfilter(searchTxt)) {
				$(this).submit();
			}
		});
		// bind click event
		$depthLink.on('touch click', function (e) {
			// DC-1661
			if ([
				'gnb-b2c_exposed_mega_nav-category_link', 
				'gnb-b2b_exposed_mega_nav-category_link', 
				'gnb-business_toggle'
			].includes($(this).attr('data-link-area')) && $(this).attr('href') !== '#') {
				return true;
			}


			var linksAllowed = [
				'/us',
				'/us/support',
				'/us/business',
				window.location.origin + '/us',
				window.location.origin + '/us/support',
				window.location.origin + '/us/business',
			];
			// DC-1661 End
			
			var allowUri = $(this).attr('href');
			if(window.location.origin.indexOf("https://sso") > -1) {
				var frontDomain = $('#curDomain').val();
				allowUri = allowUri.replace(frontDomain, "");
			}

			if ('ontouchstart' in document.documentElement || !linksAllowed.includes(allowUri)) { // DC-1661
				e.preventDefault();
				// console.log('test1');
				$(this).trigger('focus');
			} else {
				// console.log('test2');
				return true;
			}
		});
		$depthLink.on('focus', function (e) {
			e.preventDefault();
			if ($(this).is($scrollBtn)) return false;
			var _this = $(this);

			// DC-1661 Start
			// If shop or support button clicked while menu is open, close menu and remove active class
			if ((_this.is($productSupportButton) || _this.is($shopBtn)) && _this.hasClass('active')) {
				_this.removeClass('active');
				$nav.find('.left-btm .tablet-layer').removeClass('active');
				
				if (_this.is($shopBtn)) {
					$underline.removeClass('active');
				}
				return false;
			}
			// DC-1661 End

			isMouseOver = true;
			setTimeout(function() {
				if(!isMouseOver) return false;

				// DC-1661 Start
				if (_this.is($shopBtn)) {
					$underline.addClass('active');
				} else if (_this.parent().parent().hasClass('depth2') && !_this.is($shopBtn)) {
					$underline.removeClass('active');
				}
				// DC-1661 End

				var $contain = _this.closest('ul'); // depth1 or depth2
				var $tabletLayer = $contain.closest('.navigation').find('.left-btm .tablet-layer');
				$contain.find('li a').not(_this).removeClass('active');
				/* LGEUS-630 : 20200709 modify */
				$('.depth1').find('a[id^=gnbCategory]').attr('aria-expanded', false);
				_this.addClass('active').attr('aria-expanded', true);
				/*// LGEUS-630 : 20200709 modify */
				// close search lyaer
				$nav.find('.search-result-layer').removeClass('active');

				// control right area
				$icons.find('.gnb-login').removeClass('active');

				var $window = $(window).width();

				if ($contain.hasClass('depth1')) {
					// tablet-layer
					$tabletLayer.removeClass('active').empty();

					// controlArrow
					controlScrollX(window.matchMedia('(min-width: 768px) and (max-width: 1325px)')); // 초기 실행
					controlArrow();

					// slick
					var dt = 768;
					if (dt < $window) {
						//var target = '#'+_this.attr('href').split('#')[1];
						var target = '#' + _this.data('id');
						if ($(target).find('.slick-slider').length > 0) {
							_this.parent().find('.feature-box').get(0).slick.setPosition();
						}
						if (_this.hasClass('active')) {
							if (_this.next().find('.slick-dot-wrap .slide-pause').hasClass('pause')) {
								return;
							} else {
								_this.next().find('.slick-dot-wrap .slide-pause').trigger('click');
							}
						}
					}
				}

				function isEmpty(el) {
					return !$.trim(el.html());
				}

				if ($contain.hasClass('depth2')) {
					// menu
					$contain.closest('.depth1').find('>li').not($contain.closest('li')).find('> a').removeClass('active');
					$contain.closest('li').find('> a').addClass('active');
					// tablet-layer
					var $tabletnav = _this.parent().find('.sublayer');
					if ($tabletnav.length > 0) {
						// append html
						var htmlTmp = $tabletnav.clone().wrapAll("<div/>").parent().html();
						htmlTmp = xssfilter(htmlTmp, true);
						$tabletLayer.html(htmlTmp);
						$tabletLayer.addClass('active');

						// DC-1661 Start
						$tabletLayer.find('ul.nav.nav-pills.row li a').on({
							mouseenter: onSuperCategoryCategorSelect,
							click: onSuperCategoryCategorSelect,
							touchend: onSuperCategoryCategorSelect,
						});
						// DC-1661 End

						// close sublayer
						$tabletLayer.find('.sublayer .close a').off('click').on('click', function (e) {
							e.preventDefault();
							$tabletLayer.removeClass('active').empty(); // DC-1661
							$contain.find('li > a.active').removeClass('active');
						});
					}
					// slick
					if (!isEmpty(_this.parent().find('.sublayer .sublayer-inner .columns'))) {
						var dt2 = 768;
						if (dt2 < $window) {
							if (_this.parent().find('.feature-box').length > 0) {
								_this.parent().find('.feature-box').get(0).slick.setPosition();
							}
							$contain.parents('.depth2').find('.slick-initialized').slick('slickPause');
							$contain.parents('.depth2').find('.slick-dot-wrap .slide-pause').removeClass('pause').addClass('play').text(_this.attr('data-title-play'));
							if ($nav.find('.slick-dot-wrap .slide-pause').hasClass('pause')) {
								$nav.find('.slick-dot-wrap .slide-pause.pause').trigger('click');
							}
							if (_this.hasClass('active')) {
								if (_this.next().find('.slick-dot-wrap .slide-pause').hasClass('pause')) {
									//return;
								} else {
									_this.next().find('.slick-dot-wrap .slide-pause').trigger('click');
								}
							}
						}
					}
				}
			}, 300);
		});
		// 2depth x-scroll
		$scrollBtn.on('click', function (e) {
			e.preventDefault();
			if ($(this).parent().hasClass('scroll-left')) {
				$(this).closest('.scroll').find('.depth2').mCustomScrollbar('scrollTo', '+=300');
			} else {
				$(this).closest('.scroll').find('.depth2').mCustomScrollbar('scrollTo', '-=300');
			}
		});
		// 2depth x-scroll arrow
		var controlArrow = function () {
			setTimeout(function () {
				$nav.find('.mCSB_container').each(function () {
					if ($(this).hasClass('mCS_no_scrollbar_x')) {
						$(this).closest('.scroll').find('.scroll-left, .scroll-right').hide();
					} else {
						$(this).closest('.scroll').find('.scroll-left, .scroll-right').show();
					}
				});
			}, 300);
		};
		$(window).resize(function () {
			controlArrow();
		});
		var controlScrollX = function (e) {
			var scrollX = $nav.find('.depth1 .scroll .depth2');
			if (e.matches) {
				scrollX.each(function () {
					if (!$(this).hasClass('mCustomScrollbar') && $(this).closest('.depth1-holder').find('>a').hasClass('active')) {
						//console.log('start');
						$(this).mCustomScrollbar({theme: 'dark', mouseWheelPixels: 300, axis: 'x'});
					}
				});
			} else {
				scrollX.each(function () {
					if ($(this).hasClass('mCustomScrollbar')) {
						//console.log('end');
						$(this).mCustomScrollbar("destroy");
					}
				});
			}
		};
		window.matchMedia('(min-width: 768px) and (max-width: 1325px)').addListener(controlScrollX);

		// mouse out
		$nav.on('mouseleave', function (e) {
			e.preventDefault();
			var $target = $(e.target); // DC-1661
			if ($target.is($underline)) return false; // DC-1661
			isMouseOver = false;

			if ($target.hasClass('sublayer-inner') || $target.hasClass('sublayer') || $target.hasClass('navi-top')) {
				/* LGEUS-630 : 20200709 modify */
				$depthLink.removeClass('active').attr('aria-expanded', false);
				/*// LGEUS-630 : 20200709 modify */				
				$nav.find('.tablet-layer').removeClass('active');

				// DC-1661 Start
				if ($underline.hasClass('active')){
					$underline.removeClass('active');
				}
				// DC-1661 End
			}
			var $window = $(window).width();
			var dt = 768;
			if (dt <= $window) {
				if ($(this).find('.slick-dot-wrap .slide-pause').hasClass('pause')) {
					$(this).find('.slick-dot-wrap .slide-pause.pause').trigger('click');
				}
			}
		});
		$nav.find('.navi-top').on('mouseenter', function (e) {
			e.preventDefault();
			isMouseOver = false;
			$depthLink.removeClass('active');
			$nav.find('.tablet-layer').removeClass('active');
		});
		// right icons and search
		$iconBtn.on('click focus mouseenter', function (e) {
			e.preventDefault();
			var $others = $(this).closest('.icons').find('>div>a');
			$others.not($(this)).next('div').removeClass('active');
			if ($(this).next('div').length > 0) {
				if ($(this).parent().hasClass('search') && e.type != "click") {
					return false;
				} else {
					$(this).next('div').addClass('active');
					// DC-1661 Start
					if ($(this).parent().hasClass('login')) {
						$(this).addClass('active');
					}
					// DC-1661 End
				}
				if ($(this).parent().hasClass('search')) {
					$(this).next('.gnb-search-layer').find('.search-input input.input').focus();
				}
			} else {
				if(e.type=='click') {
					/*LGEGMC-777*/
					if($(this).hasClass("after-login")&&$(this).parents(".for-mobile").length){
						return true;
					}else{
						location.href=$(this).attr('href');
					}
					/*//LGEGMC-777*/

				}
				return true;
				
			}
			// close menu layer
			if ($nav.find('.depth1 > li > a.active, .depth2 li > a.active').length > 0) {
				$nav.find('.depth1 > li > a.active, .depth2 li > a.active').removeClass('active');
			}

			// control tablet layer area
			$('.tablet-layer').empty().removeClass('active');
		});
		$icons.on('mouseleave', function (e) {
			e.preventDefault();
			//console.log(e.target);
			if (!$(':focus').is($searchInput) && !$(e.target).hasClass('login')) {
				var $others = $(this).find('>div>a+div');
				$others.removeClass('active');
				$(this).find('.login a').removeClass('active'); // DC-1661
			}
		});
		$searchInput.off().on('keyup input', function (e) {
			e.preventDefault();

			// close other layer
			$nav.find('.for-desktop .left-btm ul.depth1>li>a.active').removeClass('active');
			$nav.find('.for-desktop .left-btm ul.depth2 li>a').removeClass('active');
			$nav.find('.tablet-layer').removeClass('active');

			var $searchForm = $(this).closest('.search').find('.gnb-search-form');
			var $searchResultContainer = $searchForm.find('.search-result-layer');
			var txt = $(this).val();
			var url = $(this).data('predictive-url');
			var param = $searchForm.serialize();
			if (txt.length >= 1) {
				ajax.call(url, param, 'html', function (data, _this) {
					if (!data || data == '') {
						$searchResultContainer.removeClass('active').empty();
					} else {
						$searchResultContainer.addClass('active').html(data);

						// DC-3818 Start
						$searchResultContainer.find('.add-to-cart').each(function() {
							var $productText = $(this).siblings('.product').find('.name').text();
							$(this).append('<span class="visually-hidden">, ' + $productText + '</span>');
						});
						// DC-3818 End

						$searchResultContainer.find('.search-layer .enhanced-products ul li .txt a.product').off('click').on('click', function() {
							adobeTrackEvent('gnb-search-product', {search_keyword : $(this).closest('.gnb-search-form').find('.search-input input').val(), page_event : {predictive_search_click : true}});
						});
					}
				});
			} else {
				$searchResultContainer.removeClass('active').empty();
			}

		});
		$('body').on('click touchend', function (e) {
			if (!$(e.target).parents('.navigation')[0]) {
				$nav.find('.gnb-search-layer.active').removeClass('active');
			}
		});
		$searchForms.on('click', '.search-result-layer .close', function(e) {
			//$(this).closest('.search').find('.gnb-search-form .search-result-layer').removeClass('active').empty();
			$searchForms.find('.search-submit input.submit').focus();
		});
		$searchForms.find('.search-submit input.submit').on('focus', function(e) {
			$(this).closest('.search').find('.gnb-search-form .search-result-layer').removeClass('active').empty();
		});
		// close sublayer
		$closeSubLayer.on('click', function (e) {
			e.preventDefault();
			$(this).closest('.sublayer').parent().find('>a').removeClass('active');
		});

		// feature box
		var featureSlick = function () {
			var $featureObj = $nav.find('.gnb-feature');
			$featureObj.each(function () {
				var _this = $(this);
				$(this).find('.feature-box').slick({
					autoplay: true,
					autoplaySpeed: 5000,
					infinite: false,
					listStyle: true, /* PJTWAUS-1 : 20200130 add */
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
					dots: true,
					appendDots: $(this).find('.dot-box')
				});
				$(this).find('.slick-dot-wrap .slide-pause').on('click', function () {
					if ($(this).hasClass('pause')) {
						$(this).removeClass('pause').addClass('play');
						$(this).text($(this).attr('data-title-play'));
						$(this).attr('aria-label',$(this).attr('data-title-play')); /* PJTWAUS-1 : 20200108 add */
						_this.find('.feature-box').slick('slickPause');
					} else {
						$(this).removeClass('play').addClass('pause');
						$(this).text($(this).attr('data-title-stop'));
						$(this).attr('aria-label',$(this).attr('data-title-stop')); /* PJTWAUS-1 : 20200108 add */
						_this.find('.feature-box').slick('slickPlay');
					}

				});
				$(this).find('.slick-dot-wrap .slide-pause').trigger('click');
			});
		};
		featureSlick();

		// mobile view
		var scrollToTop = function ($obj) {
			var t = $obj.closest('.navigation').offset().top;
			//console.log(t);
			$('html, body').stop().animate({scrollTop: t}, 300);
		};
		// close search layer in mobile view
		$nav.find('.gnb-search-layer .search-close a').off('click').on('click', function (e) {
			e.preventDefault();
			$(this).closest('.gnb-search-layer').removeClass('active');
		});
		// bind click event in mobile view
		var $mobileNav = $nav.find('.for-mobile');
		var $mobileMenu = $mobileNav.find('.menu');
		var $mobileDepth1 = $mobileNav.find('.depth1-m');
		var $mobileSuperCategories = $mobileNav.find('.sublayer-m.main .depth2-m > li > a'); // DC-1661
		var $mobileTopMenu = $mobileNav.find('.top-link');
		var $mobileMyLG = $mobileNav.find('.mylg');
		var $mobileBack = $mobileNav.find('.back');
		var welcome = $nav.hasClass('b2b') ? '#welcomeB2C' : '#welcomeB2B';
		//var $mobileLogged = $mobileNav.find('.mylg .login.logged');
		var $mobileExpand = $mobileNav.find('.depth2-m .expand');
		$mobileMenu.find('>a').on('click', function (e) {
			e.preventDefault();

			if ($mobileMenu.hasClass('open')) {
				if ($(this).attr('href') === welcome) {
					$mobileDepth1.removeClass('active');
					$(welcome).addClass('active');
					return;
				} else if ($(welcome).hasClass('active')) {
					$mobileDepth1.addClass('active');
					$(welcome).removeClass('active');
					return;
				}
			}

			$(this).parent().toggleClass('open').find('.menu-wrap').toggleClass('active');
			/* LGEUS-630 : 20200713 add */
			if($(this).parent().hasClass('open')){
				$(this).attr('aria-expanded', true);
			}else{
				$(this).attr('aria-expanded', false);
			}
			/*// LGEUS-630 : 20200713 add */
			if($nav.find('.for-mobile').find('.bg-drop').length==0) {
				$(this).parent().append('<div class="bg-drop"></div>');
			}
			
			// active current page
			if($nav.find('.for-mobile').find('.current-page').length==1) {
				var $currMenu = $nav.find('.for-mobile').find('.current-page');
				if($currMenu.is('.type2') || $currMenu.closest('.type3').length>0 || $currMenu.is('.expand')) {
					$currMenu.closest('.sublayer-m').addClass('active').siblings().removeClass('active');
					if($currMenu.closest('.sub').length>0) {
						$currMenu.closest('.sub').prev().addClass('active').siblings().removeClass('active');
					}
				} else if($currMenu.parent().parent().is('.type1')) {
					
				}
			} else {
				$mobileDepth1.addClass('active');
				$mobileTopMenu.addClass('active');
				$mobileMyLG.addClass('active');
				$(this).parent().find('.sublayer-m').removeClass('active');
			}
			scrollToTop($(this));
		});
		$mobileNav.on('keyup', function (e) {	// LGEUS-631 접근성 개선안 처리
			var i = e.keyCode;		
			var menu_focus = $mobileMenu.find('> a').is(':focus');		
			var menu_open = $mobileMenu.hasClass('open');
			var menu_target = $(".menu-wrap").find('a').is(e.target);
			if(i == 9 && menu_focus == false && ( menu_open == true && menu_target == false)){
				$mobileMenu.removeClass('open').find('.menu-wrap').removeClass('active');
			} 	
		});	
		$mobileMenu.on('touch click', '.bg-drop', function(e) {
			e.preventDefault();
			$mobileMenu.removeClass('open').find('.menu-wrap').removeClass('active');
			scrollToTop($(this));
		});

		// DC-1661 Start
		function onMobileCategorySelect(e) {
			if ($(this).attr('data-link-area') !== 'gnb-business_toggle') {
				e.preventDefault();
				var targetID = $(this).attr('href').split('#')[1];
				if (targetID && targetID.length > 0) {
					var $target = $('#' + targetID);

					if (!isMultishop) {
						$('.sublayer-m.active').removeClass('active'); // DC-1661
					}
					
					$mobileDepth1.removeClass('active');
					$mobileTopMenu.removeClass('active');
					$mobileMyLG.removeClass('active');
					/* LGEUS-630 : 20200710 modify */
					$target.addClass('active').find('.depth2-m > .type1').eq(0).find('a').attr('aria-expanded', true);
					/*// LGEUS-630 : 20200710 modify */
					scrollToTop($(this));
				}
			}
		}

		$mobileDepth1.find('>li>a').on('click', onMobileCategorySelect);
		if (!isMultishop) {
			$mobileSuperCategories.on('click', onMobileCategorySelect);
		}

		$mobileNav.find('.depth2-m a[href="#"]').on('click', function() {
			return false;
		});

		// DC-1661 End

		$mobileBack.find('>a').on('click', function (e) {
			e.preventDefault();
			// DC-1661 Start
			if (!isMultishop) {
				if (['#gnbshopM', '#'].includes($(this).attr('href'))) {
					if($('#gnbB2C').length > 0) {
						$('#gnbB2C').addClass('active');
					} else if($('#gnbB2B').length > 0) {
						$('#gnbB2B').addClass('active');
					}
				} else {
					$('#gnbshopM').addClass('active');
				}
			} else {
				$mobileDepth1.addClass('active');
			}
			// DC-1661 End
			
			$mobileTopMenu.addClass('active');
			$mobileMyLG.addClass('active');
			$(this).closest('.sublayer-m').removeClass('active');
			scrollToTop($(this));
		});
		$mobileExpand.find('>a').on('click', function (e) {
			e.preventDefault();
			$(this).parent().toggleClass('active');
			/* PJTWAUS-1 : 20200117 add */
			if($(this).parent().hasClass('active')){
				$(this).attr('aria-expanded', true);
			}else{
				$(this).attr('aria-expanded', false);
			}
			/*// PJTWAUS-1 : 20200117 add */
		});

		// DC-3940 & DC-3985 Happiness logo tagging & alt fix
		$('a[href="http://www.lgexperiencehappiness.com/"]')
			.attr('data-link-name', 'lg_happiness')
			.attr('data-link-area', 'gnb-lg_brand_logo')
			.find('> img')
			.attr('alt', 'LG Experience Happiness');

		// Web accessibility
		// DC-3718 WA Changes start

		function addAriaLabelAndRole($el, text) {
			$el.attr('role', 'button').attr('aria-label', text);
		}

		// Add aria-label and role="button" to <a> tags used as buttons
		// Shop button
		addAriaLabelAndRole($shopBtn, $shopBtn.data('link-name'));
		// User icon button
		addAriaLabelAndRole($nav.find('.login > a'), 'Open My LG');
		// Mobile hamburger menu
		addAriaLabelAndRole($nav.find('.for-mobile .menu > a'), 'Open Menu');
		// Mobile shop button
		var $mobileShopButton = $nav.find('.for-mobile .menu-wrap > .depth1-m > .super:first-child a');
		addAriaLabelAndRole($mobileShopButton, 'Open ' + $mobileShopButton.text());
		// Mobile product support button
		addAriaLabelAndRole($nav.find('#gnbproductsupportM'), 'Open Product Support');
		// Mobile super category buttons
		var $mobileSuperCategoryButtons = $nav.find('.for-mobile .sublayer-m.main .sections1 > a');
		$mobileSuperCategoryButtons.each(function() {
			addAriaLabelAndRole($(this), 'Open ' + $(this).text());
		});
		// Mobile back buttons
		var $mobileBackButtons = $nav.find('.for-mobile .sublayer-m .back > a');
		$mobileBackButtons.each(function() {
			var text = $(this).find('span').text();
			var label;
			if (text.includes('Back to')) {
				label = text;
			} else {
				label = 'Back to ' + text;
			}
			addAriaLabelAndRole($(this), label);
		});
		// Open search button
		addAriaLabelAndRole($nav.find('.search > a'), 'Open Search');
		// Close search button
		addAriaLabelAndRole($nav.find('.search-close > a'), 'Close Search');

		// Search WA changes
		var $searchForms = $nav.find('form.gnb-search-form');
		var $mobileSearchForm = $searchForms.filter(function() {
			return !!$(this).parents('.for-mobile').length;
		});
		// Add autocomplete="off"
		$searchForms.attr('autocomplete', 'off');
		// Add role="search"
		$searchForms.attr('role', 'search');
		// Add aria-owns to mobile input
		var ariaOwns = 'gnb-search-result-layer-mobile';
		$mobileSearchForm.find('input[name=search]').attr('aria-owns', ariaOwns);
		$mobileSearchForm.find('.search-result-layer').attr('id', ariaOwns);

		// Add input label to mbile
		var $inputLabelMobile = $('<label class="sr-only" for="gnb_search_tit_mobile">Search LG</label>');
		$mobileSearchForm.find('.search-input').prepend($inputLabelMobile);
		$mobileSearchForm.find('input[name=search]').attr('id', 'gnb_search_tit_mobile');

		// DC-3718 WA Changes end

		// DC-3818 WA Changes start
		var $addToCartButtons = $('.add-to-cart, .add-to-cart-popup, .subscription-add-to-cart');

		if ($('.C0009').length > 0) {
			var $productText = $('.C0009').find('.model-title-desktop-pdp-new').text();
			$addToCartButtons.append('<span class="visually-hidden">, ' + $productText + '</span>');
		} else if ($('.C0007').length > 0) {
			$addToCartButtons.each(function() {
				var $productText = $(this).closest('.ga-model-info').find('.ga-model-detail').text();
				$(this).append('<span class="visually-hidden">, ' + $productText + '</span>');
			});
		}
		// DC-3818 WA Changes end

		// DC-4664 Start
		
		console.log('Nav_Function:',($nav.hasClass('b2c') && $nav.data('store') == 'us'));
		if ($nav.hasClass('b2c') && $nav.data('store') == 'us') {
			var actualDomain = $('.curDomain').val() == null ? "" : $('.curDomain').val();
			var searchAction = actualDomain + '/us/search';
			// if (window.location.origin !== 'https://www.lg.com') {
			// 	searchAction = actualDomain + '/us/shopping/search';
			// }
			
			$searchForms.attr('action', searchAction);
			$('#gnb_search_tit').attr('name', 'q');
		}
		// DC-4664 End

		loginCheck();
	}
}());

// top btn
(function () {
	if (!document.querySelector('.floating-menu')) return false;

	var floatingMenu = function () {
		var $this = $(".floating-menu"),
			p = floatingMenu.prototype;

		p.init = function () {
			this.elements.$toTop.on("click", function (e) {
				e.preventDefault();
				$("html, body").stop().animate({
					scrollTop: 0
				}, 600);
				/* LGEUS-728 : 20200713 modify */ 
				if($("#skip_header").length > 0){
					$("#skip_header").attr("tabindex","0").focus();  // LGEUS-633 접근성 개선안 처리
				} else{
					$(".skip_nav:first").attr("tabindex","0").focus();
				}
				/* //LGEUS-728 : 20200713 modify */ 
			});
			$(window).on("scroll", function () {
					/* 	Sanmi: Disabled this call-yet class because of this ticket
					 		Trello: https://trello.com/c/4CdlGaGY/16-online-support-chat-updates
							DXP Jira: https://dxporg.atlassian.net/browse/DC-3034
					*/

				// var scrollPos = $(window).scrollTop(),
				// 	h = $('header.navigation').outerHeight();
				// if (!$this.hasClass('call-yet') && (scrollPos <= h)) {
				// 	$this.addClass("call-yet");
				// } else if ($this.hasClass('call-yet') && (scrollPos > h)) {
				// 	$this.removeClass("call-yet");
				// }
			});
			$this.removeClass("call-yet");
		};
		p.elements = {
			$toTop: $this.find(".back-to-top"),
			$chatbot: $this.find(".chatbot-linker")
		};
		p.init();
	};
	floatingMenu();
}());

// footer	
(function($) {
	var appFooter = function(){
		var footerObj = $('.footer-main-contents');
		var footerTarget = footerObj.find('.visible-mobile');
		var footerDepth1 = footerTarget.find('.has-category');
		var isMultishop = ['eos', 'pm'].includes($('.navigation').data('store'));

		// DC-3662 
		// Add "data-multishop" attribute to div's w/ class "footer-bottom-box" where value is "Y" if it is multishop and "N" if it is not
		// This is so new CSS styles do not change multishop footer styles
		footerObj.find('.footer-bottom-box').attr('data-multishop', isMultishop ? 'Y' : 'N');

		footerDepth1.on('click', function (e) {
			var $this = $(this);
			if (!$this.hasClass('on')) {
				$this.addClass('on');
				/* PJTWAUS-1 : 20191210  add, LGEUS-631 20200624 modify */
				$this.find('a[aria-expanded]').attr('aria-expanded', true);
				/*// PJTWAUS-1 : 20191210 add, LGEUS-631 20200624 modify */
				$this.next().slideDown(200);
				$this.append('<a href="#" class="button-layer" role="button"></a>');
				layerButton();
				return false;
			}
		});
	};
	var layerButton = function () {
		$('.footer-main-contents .button-layer').off("click").on('click', function () {
			var $this = $(this);
			$this.parent().removeClass('on');
			/* PJTWAUS-1 : 20191210 add, LGEUS-631 20200624 modify */
			$this.siblings('a[aria-expanded]').attr('aria-expanded', false);
			/*// PJTWAUS-1 : 20191210 add, LGEUS-631 20200624 modify */
			$this.parent().next().slideUp(200);
			$this.off("click").remove();
			return false;
		});
	};
	appFooter();
})(jQuery);

// B2B Search
(function($) {
	if (!document.querySelector('.navigation')) return false;
	var isB2C = $('.navigation').hasClass('b2c');
	var isB2B = $('.navigation').hasClass('b2b');

	function getSearchHistoryEntries() {
		var searchHistoryObj = JSON.parse(localStorage.getItem('persist:searchHistory'));
		var entries = [];
		if (searchHistoryObj && searchHistoryObj.data) {
			entries = JSON.parse(searchHistoryObj.data);
		}
		return entries;
		
	}

	function setSearchHistoryLocalStorage(entries) {
		var newHistory = JSON.stringify({data: JSON.stringify(entries), _persist: '{"version":-1,"rehydrated":true}'});
		localStorage.setItem('persist:searchHistory', newHistory);
	}

	function appendSearchResultSet(products, searchQuery, coveoToken, coveoClickUrl, clickAnalyticsData) {
		products = products.slice(0, 3);
		var $container = $('.result-items-container .result-items');
		$container.html('');

		if (!products.length) {
			$('.result-items-container').removeClass('active');
			$container.html('');
		} else {
			$('.result-items-container').addClass('active');

			// Search event data needed for click events
			var originLevel1 = clickAnalyticsData.originLevel1;
			var searchQueryUid = clickAnalyticsData.searchQueryUid;
			var language = clickAnalyticsData.language;

			for (var i = 0; i < products.length; i++) {
				if(!products[i].numberOfResults) {
					var product = products[i];
					var productStatus = product.raw.ec_model_status_code;
	
					if (productStatus === 'SUSPENDED') {
						continue;
					}
	
					var productName = product.Title;
					var productPrice = (product.ec_obs_selling_price_string === '$0.00') ? product.ec_msrp_string : product.ec_obs_selling_price_string;
					var productModel = product.raw.ec_model_display_name;
					var productModelCode = product.raw.ec_sales_model_suffix_code;
					var productImage = product.raw.ec_medium_image_addr;
					var productImageAltText = product.raw.ec_image_alt_text;
					var productSku = product.raw.ec_sales_model_code || productModel;
					var productModelId = product.raw.ec_model_id;
					var productUrl = product.raw.clickableuri;
					var productSuperCategoryId = product.raw.ec_super_category_id;
					var findADealerFlag = product.raw.ec_wtb_use_flag; // Where to buy, find a dealer are the same concept
					var whereToBuyUrlLink = product.raw.ec_wtb_external_link_url;
					var externalLinkName = product.raw.ec_wtb_external_link_name;
					

					var priceTypeCode = product.ec_price_type_code;
					var ctaTypeCode = product.ec_cta_type_code;

					// Coveo click analytics data specific to product
					var actionCause = product.actionCause;
					var documentPosition = product.DocumentPosition;
					var documentTitle = product.documentTitle;
					var documentUri = product.documentUri;
					var documentUriHash = product.documentUriHash;
					var documentUrl = product.documentUrl;
					var sourceName = product.sourceName;
					var rankingModifier = product.rankingModifier;
					
					// var hasAddtoCart = (
					// 	product.raw.ec_ecommerce_flag === 'Y' &&
					// 	product.raw.ec_obs_original_price !== null &&
					// 	product.raw.ec_obs_selling_price !== null &&
					// 	product.raw.ec_obs_sell_flag === 'Y' &&
					// 	!!product.raw.ec_obs_inventory_flag
					// );

					// CTA codes
					var hasAddtoCart = ctaTypeCode === 'CART';
					var hasInquiryToBuy = ctaTypeCode === 'INQUIRY' // B2B logic: subject to change
					var hasNotifyMe = ctaTypeCode === 'NOTIFY';
					var hasFindARetailerOnly = ctaTypeCode === 'RETAIL';
					var hasFindADealerOnly = ctaTypeCode === 'DEALER'; // B2B logic: subject to change
					var isExternal = ctaTypeCode === 'EXTERNAL';
					var hideCta = ctaTypeCode === 'HIDE';
					
					// Price type codes
					var showRetailerPricing = priceTypeCode === 'RETAIL';
					var hidePrice = priceTypeCode === 'HIDE';

					var isPreorderItem = ['Preorder', 'Pre-Order'].includes(externalLinkName);

					if (showRetailerPricing) {
						productPrice = 'See retailer for pricing';
					}
					
					var template = (
						'<div class="result-item" data-action-cause="#actionCause#" data-document-position="#documentPosition#" data-document-title="#documentTitle#" data-document-uri="#documentUri#" data-document-uri-hash="#documentUriHash#" data-document-url="#documentUrl#" data-language="#language#" data-origin-level="#originLevel1#" data-search-query-uid="#searchQueryUid#" data-source-name="#sourceName#" data-ranking-modifier="#rankingModifier#">'+
							'<div class="result-alert"></div>'+
							'<div class="result-info">'+
								'<div class="result-image-container">'+
									'<a href="#productImgUrl#" target="_blank" class="result-image-container-inner">'+
										'<div class="result-image">'+
											'<img loading="lazy" src="#productImage#" alt="#productNameAlt#" width="100%" height="100%">' +
										'</div>'+
									'</a>'+
								'</div>'+
								'<div class="product-info-container">'+
									'<div class="product-info-container-inner">'+
										'<p class="product-info-model-name">#productModel#</p>'+
										'<a href="#productNameUrl#" target="_blank">' +
											'<h6 class="product-info-name">#productName#</h6>'+
										'</a>' +
										'<p class="product-info-price">#productPrice#</p>'+
									'</div>'+
								'</div>'+
							'</div>'+
						'</div>'
					)
					.replace('#productName#', productName)
					.replace('#productNameAlt#', productImageAltText)
					.replace('#productPrice#', productPrice)
					.replace('#productModel#', productModel)
					.replace('#productImage#', productImage)
					.replace('#productImgUrl#', productUrl)
					.replace('#productNameUrl#', productUrl)
					.replace('#actionCause#', actionCause)
					.replace('#documentPosition#', documentPosition)
					.replace('#documentTitle#', documentTitle)
					.replace('#documentUri#', documentUri)
					.replace('#documentUriHash#', documentUriHash)
					.replace('#documentUrl#', documentUrl)
					.replace('#sourceName#', sourceName)
					.replace('#rankingModifier#', rankingModifier)
					.replace('#language#', language)
					.replace('#originLevel1#', originLevel1)
					.replace('#searchQueryUid#', searchQueryUid);

					var $resultItem = $(template);
					var $alert = $resultItem.find('.result-alert');
					
					function handleAddToCart(e) {
						e.preventDefault();
						// Magento uses model-id as sku
						var sku = $(this).data('sku');
						if (addToCart.cartID) {
							addToCart.addProductToCart(sku, 1, addToCart.cartID);
						} else {
							addToCart.createEmptyCart(sku, $(this));
						}
					}
	
					var isDiscontinued = productStatus === 'DISCONTINUED';
					var $productOptionsContainer = $('<div class="result-options"></div>');
					var $mobileProductOptionsContainer = $('<div class="result-options-mobile"></div>');

					function appendAddToCartButton() {
						var $addToCartButton = $(
							'<a role="button" href="#addToCart" class="btn" data-link-name="add_to_cart"  data-toggle="modal" data-sku="' + productModelId + '">Add To Cart</a>'
						);
						var $mobileAddToCartButton = $addToCartButton.clone();
	
						$addToCartButton.on('click', handleAddToCart);
						$mobileAddToCartButton.on('click', handleAddToCart);
	
						$productOptionsContainer.append($addToCartButton);
						$mobileProductOptionsContainer.append($mobileAddToCartButton);
					}

					function appendNotifyMeButton() {
						$alert.addClass('unavailable');
						$alert.text('Out of Stock');
						var $notifyMeButton = $(
							'<a role="button" href="#emailAvailable" class="btn out-of-stock" data-link-name="notify_me"  data-toggle="modal" title="Opens in a new popup">Notify Me</a>'
						);
						$productOptionsContainer.append($notifyMeButton);
						$mobileProductOptionsContainer.append($notifyMeButton.clone());
					}

					function appendDealerOrRetailerButton() {
						if (!isPreorderItem) {
							var findARetailerData = {
								text: 'Find a Retailer',
								param: '#pdp_where',
								class: 'find-a-retailer',
								linkName: 'find_a_retailer'
							};
							var findADealerData = {
								text: 'Find a Dealer',
								param: '#pdp_findadealer',
								class: 'find-a-dealer',
								linkName: 'find_a_dealer'
							};
							var data = isB2C ? findARetailerData : findADealerData;
							var $button = $(
								'<a href="' + productUrl + data.param + '" class="btn ' + data.class + '" data-link-name="' + data.linkName + '">' + data.text + '</a>'
							);
							$productOptionsContainer.append($button);
							$mobileProductOptionsContainer.append($button.clone());
						}
					}

					function appendInquiryToBuyButton() {
						var inquiryToBuyUrl = '/us/business/inquiry-to-buy?selectItem=Y&superCateId=' + productSuperCategoryId;
						var $inquiryToBuyButton = $(
							'<a href="' + inquiryToBuyUrl + '" class="btn inquiry-to-buy" data-link-name="inquiry_to_buy">Inquiry to Buy</a>'
						);
						$productOptionsContainer.append($inquiryToBuyButton);
						$mobileProductOptionsContainer.append($inquiryToBuyButton.clone());
					}

					// For external
					function appendWhereToBuyButton() {
						var whereToBuyUrl = whereToBuyUrlLink;
						var $whereToBuyButton = $(
							'<a href="' + whereToBuyUrl + '" class="btn  where-to-buy" data-link-name="where_to_buy">' + externalLinkName + '</a>'
						);
						$productOptionsContainer.append($whereToBuyButton);
						$mobileProductOptionsContainer.append($whereToBuyButton.clone());
					}

					if (!hideCta) {
						if (!isDiscontinued) {
							// Logic for adding buttons based on where to buy, inquiry to buy, etc.
							productPrice = product.ec_obs_original_price_string;
							var $productOptionsContainer = $('<div class="result-options"></div>');
							var $mobileProductOptionsContainer = $('<div class="result-options-mobile"></div>');
							

							if (hasAddtoCart) {
								appendAddToCartButton();
								!!externalLinkName ? appendWhereToBuyButton() : appendDealerOrRetailerButton();
							} else if (hasInquiryToBuy) {
								appendInquiryToBuyButton();
							} else if (hasNotifyMe) {
								appendNotifyMeButton();
								appendDealerOrRetailerButton();
							} else if (hasFindARetailerOnly || hasFindADealerOnly) {
								appendDealerOrRetailerButton();
							} else if (isExternal && (product.raw.ec_wtb_external_link_url)) {
								appendWhereToBuyButton();
							}
							$resultItem.find('.product-info-container').append($productOptionsContainer);
							$resultItem.append($mobileProductOptionsContainer);
						}
					}
					
					
					// Add "User Manual" and "Support" buttons if search query matches product sku
					// if (productSku) {
					// 	if (searchQuery.toLowerCase() === productSku.toLowerCase() && products.length === 1) {
					// 		var $searchSkuMatchContainer = $('<div class="sku-match"></div>');
					// 		var supportUrl = productUrl + '#pdp_support';
					// 		var manualUrl = '/us/support/product/lg-' + productModelCode;
					// 		var $supportButton = $(
					// 			'<a href="' + supportUrl + '" class="btn btn-outline-secondary">Support</a>'
					// 		);
					// 		var $manualButton = $(
					// 			'<a href="' + manualUrl + '" class="btn btn-outline-secondary">User Manuals</a>'
					// 		);
					// 		$searchSkuMatchContainer.append($supportButton);
					// 		$searchSkuMatchContainer.append($manualButton);
					// 		$resultItem.append($searchSkuMatchContainer);
					// 	}
					// }
					//Hide the suggestion and product support section if search query matches product sku
					if (productSku) {
						if (searchQuery.toLowerCase() === productSku.toLowerCase() && products.length === 1) {
							$('.search-success-product-support').removeClass('active');
							$('.search-success-suggestions').removeClass('active');
						}
					}
					
					//Hide the price if product is isDiscontinued
					if (isDiscontinued || hidePrice) {
						$resultItem.find('.product-info-price').remove();
						
					}
					
					// Remove model id element if field not sent in response
					if (!productModel) {
						$resultItem.find('.product-info-model-name').remove();
					}
					
					$resultItem.find('a').on('click', function (e) {
						e.preventDefault();
						var href = $(this).attr('href');
					 	coveoClickEvent($resultItem, coveoToken, coveoClickUrl, true, href, true);
					 });
	
					$container.append($resultItem);
				}
			}
		}
	}

	function appendMoreSuggestion(suggestions, isPreSearch, reactFrameWorkFlag) {
		suggestions = suggestions.slice(0, 3);
		var selector = isPreSearch ? '.pre-search .b2b-popular-searches-container .b2b-suggestion-results' : '.search-success-suggestions .b2b-suggestion-results';
		var $container = $(selector);
		$container.html('');
		// var navUrl = isB2C? '/us/shopping/search?q=' : isB2B ? '/us/search/search-all?type=B2B&search=': '';
		
		var actualDomain = $('.curDomain').val() == null ? "" : $('.curDomain').val();
		var navUrl = (isB2C && reactFrameWorkFlag === 'Y')? (actualDomain + '/us/shopping/search?q=') : actualDomain + ((isB2C && reactFrameWorkFlag === 'N') ? '/us/search/search-all?type=B2C&search=':isB2B ? '/us/search/search-all?type=B2B&search=': '');

		if (!suggestions.length) {
			$('.search-success-suggestions').removeClass('active');
		} else {
			$('.search-success-suggestions').addClass('active');
			for (var i = 0; i < suggestions.length; i++) {
				var searchQuery = navUrl + suggestions[i].expression;

				var template = (
					'<div class="b2b-suggestion-results-list-item">'+
						'<a href="#searchQuery#" role="button" class="b2b-suggestion-results-item-name">'+
							'<img alt="search" class="b2b-suggestion-search" src="' + actualDomain + '/lg5-common/images/common/icons/search-nero.svg"  />'+
							'<span>'+ suggestions[i].highlighted.replaceAll('[', '<i>').replaceAll(']','</i>').replaceAll('{','<strong>').replaceAll('}','</strong>').replaceAll('(', '<i>').replaceAll(')','</i>')+'</span>'+
						'</a>'+
					'</div>'
				)
				.replace('#searchQuery#', searchQuery);

				var $suggestionItem = $(template);
				$container.append($suggestionItem);
			}
		}
	}

	function appendProductSupport(query){
		$('.search-success-product-support').addClass('active');
		var $container = $('.search-success-product-support .b2b-suggestion-results');
		$container.html('');
		
		var actualDomain = $('.curDomain').val() == null ? "" : $('.curDomain').val();
		var template = (
			'<div class="b2b-suggestion-results-list-item">'+
				'<a href="' + actualDomain + '/us/search/support?search=#query#" role="button" class="b2b-suggestion-results-item-name">'+
					'<img alt="search" class="b2b-suggestion-search" src="' + actualDomain + '/lg5-common/images/common/icons/search-nero.svg"  />'+
					'<span><strong>'+query+'</strong>&nbsp;in support</span>'+
				'</a>'+
			'</div>'
		)
		.replace('#query#', query);

		var $ProductSupportItem = $(template);
		$container.append($ProductSupportItem);
	}

	function appendSearchHistoryItem(searchTerm, reactFrameWorkFlag) {
		var searchHistory = getSearchHistoryEntries();
		var actualDomain = $('.curDomain').val() == null ? "" : $('.curDomain').val();
		var navUrl = (isB2C && reactFrameWorkFlag === 'Y')? (actualDomain+'/us/shopping/search?q=') : actualDomain+((isB2C && reactFrameWorkFlag === 'N') ? '/us/search/search-all?type=B2C&search=':isB2B ? '/us/search/search-all?type=B2B&search=': '');
		var searchParam = navUrl + encodeURIComponent(searchTerm);

		var template = (
				'<div class="b2b-suggestion-results-list-item">' +
				'<a href="#searchParam#" class="b2b-suggestion-results-item-name">' +
					'<img alt="Search" class="b2b-suggestion-search" src="' + actualDomain + '/lg5-common/images/common/icons/search-nero.svg" />' +
					'<span>#searchTerm#</span>' +
				'</a>' +
				'<a href="#" role="button" class="b2b-suggestion-cancel">' +
					'<span class="sr-only">Close</span>' +
				'</a>' +
			'</div>'

		)
		.replace('#searchParam#', searchParam)
		.replace('#searchTerm#', searchTerm);

		var $historyItem = $(template);
		var $container = $('.b2b-history-container .b2b-suggestion-results');
		$historyItem.find('a.b2b-suggestion-cancel').on('click', function(e) {
			e.preventDefault();
			$historyItem.remove();
			var updatedSearchHistory = searchHistory.filter(function(entry) {
				return entry !== searchTerm;
			});
			setSearchHistoryLocalStorage(updatedSearchHistory);
			if (!$container.children().length) {
				searchModal.hideSearchHistory();
			}
		});

		$container.append($historyItem);

		if (!$(searchModal.modalSearchHistory).hasClass('active')) {
			$(searchModal.modalSearchHistory).addClass('active');
			$(searchModal.modalPopularSearches).removeClass('active');
		}
	}

	var searchModal = {
		searchSubmitUrl: '/us/mkt/api/gnbSearchb2b',
		searchParam : JSON.stringify({ search: '' }),
		modalButton: document.querySelector('.icons .b2b-search > a'),
		mobileModalButton: document.querySelector('.for-mobile .icons .search > a'),
		modalSearchSubmitButton: document.querySelector('.b2b-search-submit > a'),
		modalSearchCancelButton: document.querySelector('.b2b-search-cancel > a'),
		modalInputEl: document.querySelector('.b2b-search-input > input.input'),
		modalReactFlag: document.querySelector('.b2b-search-input > input.b2b-search'),
		modalElement: document.querySelector('.search .modal.fade'),
		modalPreSearchContainer: document.querySelector('.b2b-search .pre-search'),
		modalResultsContainer: document.querySelector('.b2b-search .search-success'),
		modalLoadingSpinner: document.querySelector('.b2b-search .loading'),
		modalCancelButton:document.querySelector('.modal-close'),
		modalSearchHistory: document.querySelector('.b2b-search .b2b-history-container'),
		modalClearHistoryButton: document.querySelector('.b2b-history a.b2b-search-clear'),
		modalPopularSearches: document.querySelector('.b2b-search .b2b-popular-searches-container'),
		handleInputChange: debounce(function() {
			var inputValue = xssfilter($(searchModal.modalInputEl).val().trim(), 'form');

			if (!inputValue.length) {
				searchModal.clearSearchInput();
				return;
			}

			if (inputValue.length >= 2) {
				
				var actualDomain = $('.curDomain').val() == null ? "" : $('.curDomain').val();
				if(actualDomain == "") {
					data = { search: inputValue };
				} else {
					var type = $('input[name=type]').val();
					data = { search: inputValue, type: type };
				}
				
				var $resultsContainer = $(searchModal.modalResultsContainer);
				var $preSearchContainer = $(searchModal.modalPreSearchContainer);
			
				$preSearchContainer.removeClass('active');
				$resultsContainer.removeClass('active');
				searchModal.toggleLoadingSpinner();

				ajax.call(searchModal.searchSubmitUrl, data, 'json', function (data) {
					var products = data.products;
					if(!Array.isArray(products)) {
						products = Object.values(products);
					}
					var suggestions = data.suggestions;
					if(!Array.isArray(suggestions)) {
						suggestions = Object.values(suggestions);
					}
					var searchAnalyticsUrl = data.analyticsSearch;
					var clickAnalyticsUrl = data.analyticsClick;
					var coveoToken = data.token;
					var searchHistory = getSearchHistoryEntries();
					var reactFrameWorkFlag = data.reactFrameWorkFlag;
					if (!searchHistory.includes(inputValue)) {
						searchHistory.push(inputValue);
						setSearchHistoryLocalStorage(searchHistory);
						appendSearchHistoryItem(inputValue, reactFrameWorkFlag);
					}

					var clickAnalyticsData = {
						originLevel1: data.originLevel1,
						searchQueryUid: data.searchQueryUid,
						language: data.language,
					}
					
					appendProductSupport(inputValue);
					
					if (products && products.length > 0) {
						appendMoreSuggestion(suggestions, false, reactFrameWorkFlag);
						appendSearchResultSet(products, inputValue, coveoToken, clickAnalyticsUrl, clickAnalyticsData);
						coveoSearchEvent(data, inputValue, coveoToken, searchAnalyticsUrl);
					} else {
						$('.search-success-suggestions').removeClass('active');
						$('.result-items-container').removeClass('active');
					}

					searchModal.toggleLoadingSpinner();
					$resultsContainer.addClass('active');
				});	
			}
		}),
		handleInputFocus: function(e) {
			$('.b2b-search-cancel').addClass('active');
			$('.b2b-search-submit a').addClass('focused');
			var entries = getSearchHistoryEntries();
			if (entries && entries.length) {
				$(searchModal.modalSearchHistory).addClass('active');
				$(searchModal.modalPopularSearches).removeClass('active');
			}
		},
		handleInputBlur: function(e) {
			var inputValue = $(searchModal.modalInputEl).val().trim();
			
			if (!inputValue.length) {
				$('.b2b-search-cancel').removeClass('active');
				$('.b2b-search-submit a').removeClass('focused');
			}
		},
		handleSearchButtonClick: function(e) {
			e.preventDefault();
			var $modalElement = $(searchModal.modalElement);

			if ($modalElement.parents('.search').length) {
				$modalElement.appendTo('body');
			}
			$modalElement.modal('show');
		},
		handleSearchSubmit: function(e) {
			var inputValue = $(searchModal.modalInputEl).val().trim();
			var reactflag = xssfilter($(searchModal.modalReactFlag).val());
			var actualDomain = $('.curDomain').val() == null ? window.location.origin : $('.curDomain').val();
			if (inputValue.length) {
				if (isB2B) {
					window.location.href = actualDomain + '/us/search/search-all?type=B2B&search=' + inputValue;
				} else if (isB2C) {
					var reactRedirect = actualDomain + '/us/search?q=' + inputValue;
					var nonReactRedirect = actualDomain + '/us/search/search-all?type=B2C&search=' + inputValue;
					window.location.href = reactflag === 'Y' ? reactRedirect : nonReactRedirect;
				}
			}
		},
		handleSearchSubmitEnter: function(e) {
			var inputValue = $(searchModal.modalInputEl).val().trim();
			var reactflag = xssfilter($(searchModal.modalReactFlag).val());
			var entries = getSearchHistoryEntries();

			if (inputValue.length == 0) {
				if (entries && entries.length) {
					$(searchModal.modalSearchHistory).addClass('active');
				} else {
					$(searchModal.modalPopularSearches).addClass('active');
				}
				$('.result-items-container').removeClass('active');
			}

			var actualDomain = $('.curDomain').val() == null ? window.location.origin : $('.curDomain').val();
			if (e.keyCode === 13 && inputValue.length) {
				if (isB2B) {
					window.location.href = actualDomain + '/us/search/search-all?type=B2B&search=' + inputValue;
				} else if (isB2C) {
					var reactRedirect = actualDomain + '/us/search?q=' + inputValue;
					var nonReactRedirect = actualDomain + '/us/search/search-all?type=B2C&search=' + inputValue;
					window.location.href = reactflag === 'Y' ? reactRedirect : nonReactRedirect;
				}
			}
			
			if (inputValue.length > 26) {
				return false;
			}
		},
		modalClearButton:function(e) {
			$(searchModal.modalInputEl).val('');
			$(searchModal.modalResultsContainer).removeClass('active');
			$(searchModal.modalPreSearchContainer).addClass('active');
		},
		hideSearchHistory: function() {
			setSearchHistoryLocalStorage([]);
			$('.b2b-history-container').find('.b2b-suggestion-results-list-item').remove();
			$(searchModal.modalSearchHistory).removeClass('active');
			$(searchModal.modalPopularSearches).addClass('active');
		},
		clearSearchInput: function() {
			$(searchModal.modalInputEl).val('');
			$(searchModal.modalResultsContainer).removeClass('active');
			$(searchModal.modalPreSearchContainer).addClass('active');
		},
		toggleLoadingSpinner: function() {
			var $spinner = $(searchModal.modalLoadingSpinner);
			if ($spinner.hasClass('active')) {
				$spinner.removeClass('active');
			} else {
				$spinner.addClass('active');
			}
		},
		addEvent: function() {
			var $modalButton = $(searchModal.modalButton);
			var $mobileModalButton = $(searchModal.mobileModalButton);
			var $modalElement = $(searchModal.modalElement);
			var $modalSearchInput = $(searchModal.modalInputEl);
			var $modalSearchSubmitButton = $(searchModal.modalSearchSubmitButton);
			var $modalSearchCancelButton = $(searchModal.modalSearchCancelButton);
			var $modalClearHistoryButton = $(searchModal.modalClearHistoryButton);
			var $modalInputEl = $(searchModal.modalInputEl);
			var $modalCancelButton = $(searchModal.modalCancelButton);
			$modalSearchInput.on('input', searchModal.handleInputChange);
			$modalSearchInput.on('focus', searchModal.handleInputFocus);
			$modalSearchInput.on('blur', searchModal.handleInputBlur);
			$modalButton.on('click', searchModal.handleSearchButtonClick);
			$mobileModalButton.off().on('click', searchModal.handleSearchButtonClick);
			$modalSearchSubmitButton.on('click', searchModal.handleSearchSubmit);
			$modalInputEl.on('keypress', searchModal.handleSearchSubmitEnter);
			$modalSearchCancelButton.on('click', searchModal.clearSearchInput);
			$modalClearHistoryButton.on('click', searchModal.hideSearchHistory);
			$modalCancelButton.on('click', searchModal.modalClearButton);

			$modalElement.on({
				'shown.bs.modal': function() {
					$('body').css('max-width', '100%');
				},
				'show.bs.modal': function() {
					$('body').css('max-width', '100%');
				},
				'hidden.bs.modal': function() {
					$('body').css('max-width', 'none');
				},
			});
		},
		addNotifyMeModal: function() {
			var $notifyMeModal = $(
				'<div class="modal fade" id="emailAvailable" tabindex="-1" role="dialog" data-backdrop="true" aria-labelledby="emailAvailable_tit" data-url="/us/mylg/api/handraiser" handraiser-text="In-Stock Notification Email" aria-modal="true">' +
					'<div class="modal-dialog modal-md" role="document">' +
						'<div class="modal-content">' +
							'<button type="button" class="modal-close" data-dismiss="modal">Close</button>' +
							'<div class="modal-header"><strong class="modal-title" id="emailAvailable_tit">We\'ll let you know when it\'s back in stock</strong></div>' +
							'<div class="modal-body">' +
								'<div class="email-info">' +
									'<p class="note">Enter your information below to receive a notification when the product is available.</p>' +
									'<div class="write-area">' +
										'<div class="write-area-inputs">' +
											'<div class="write-type">' +
												'<div class="input-type">' +
													'<div class="field-block">' +
														'<span class="hiddenText" id="placeholder_zip">ZIP Code</span>' +
														'<input type="text" title="ZIP Code" id="notifyMeZip" placeholder="ZIP Code" minlength="5" maxlength="5" pattern="\\d*" aria-describedby="placeholder_zip" aria-invalid="false">' +
													'</div>' +
												'</div>' +
												'<div class="error-msg">' +
													'<span class="required">Please enter a valid ZIP code</span>' +
												'</div>' +
											'</div>' +
											'<div class="write-type">' +
												'<div class="input-type">' +
													'<div class="field-block">' +
														'<span class="hiddenText" id="placeholder_email">Enter your Email</span>' +
														'<input type="text" title="Email" id="email" placeholder="Email" aria-describedby="placeholder_email" aria-invalid="false">' +
													'</div>' +
												'</div>' +
												'<div class="error-msg">' +
													'<span class="required">Please enter a valid email</span>' +
												'</div>' +
											'</div>' +
										'</div>' +
										'<div class="agree-check">' +
											'<div class="field-block">' +
												'<label class="checkbox-box">' +
													'<input type="checkbox" name="maillAgree" required="" checked="">' +
													'<span class="checkbox-btn"></span>' +
													'<span class="text">Sign me up to receive product tips and tricks, special offers and more from LG. <br> By signing up for these emails, you are agreeing to the <a href="/us/privacy" class="link-text bold ico-right" target="_blank" title="Opens in a new window">LG Privacy Policy</a></span>' +
												'</label>' +
											'</div>' +
										'</div>' +
										'<div class="notify-me-options">' +
											'<button type="button" class="btn" data-dismiss="modal">Cancel</button>' +
											'<button type="button" class="btn btn-primary notify-mail">Notify Me</button>' +
										'</div>' +
									'</div>' +
									'<div class="success-area" style="display: none;">' +
										'<strong class="succss-tit">Thank You!</strong>' +
										'<p class="succss-desc">Your email was received. We will let you know when this product is back in stock.</p>' +
									'</div>' +
								'</div>' +
							'</div>' +
						'</div>' +
					'</div>' +
				'</div>'
			);
			$notifyMeModal.on({
				'shown.bs.modal': function() {
					$('body').css('max-width', '100%');
				},
				'show.bs.modal': function() {
					$('body').css('max-width', '100%');
				},
				'hidden.bs.modal': function() {
					$('body').css('max-width', 'none');
				},
			});
			$('body').append($notifyMeModal);
		},
		init: function() {
			// Use B2C endpoint for required B2C pages
			var pathname = window.location.pathname;
			var reactflag = xssfilter($(searchModal.modalReactFlag).val());
			
			var actualDomain = $('.curDomain').val() == null ? "" : $('.curDomain').val();
			if(actualDomain == "") {
				if ((isB2B && pathname.includes('/us/business')) || (isB2C && pathname.includes('/us/support/business'))) {
					searchModal.searchSubmitUrl = actualDomain + '/us/mkt/api/gnbSearchb2b';
					
				} else if (isB2C){
					searchModal.searchSubmitUrl = actualDomain + '/us/mkt/api/gnbSearchb2c';
				}
			} else {
				var type = $('input[name=type]').val();
				var authorizeKey = $('#authorizeKey').val() == null ? "" : $('#authorizeKey').val();
				searchModal.searchParam = JSON.stringify({ search: '', type: type })
				if(authorizeKey != "") {
					searchModal.searchSubmitUrl = '/oauth/ajax/coveoGnbSearch?authorizeKey=' + authorizeKey;
				} else {
					searchModal.searchSubmitUrl = '/oauth/ajax/coveoGnbSearch';
				}
			}

			searchModal.addEvent();
			
			var searchHistoryEntries = getSearchHistoryEntries();
			if (searchHistoryEntries && searchHistoryEntries.length) {
				for (var i = 0; i < searchHistoryEntries.length; i++) {
					appendSearchHistoryItem(xssfilter(searchHistoryEntries[i]), reactflag);
				}
			} else {
				// Show popular searches
				searchModal.hideSearchHistory();
			}
			
			// $.ajax({
			// 	crossDomain: true,
			// 	url: searchModal.searchSubmitUrl,
			// 	method: 'POST',
			// 	dataType: 'json',
			// 	contentType: 'application/json',
			// 	data: searchModal.searchParam,
			// 	success: function(data) {
			// 		var suggestions = data.suggestions;
			// 		if (suggestions!=null && suggestions.length) {
			// 			appendMoreSuggestion(suggestions, true, reactflag);
			// 		}
			// 	},
			// 	error: function(request, status, error) {
			// 		console.error('STATUS: ', status);
			// 		console.error('ERROR: ', error);
			// 	},
			// }); 

			if ($('#emailAvailable').length) {
				$('#emailAvailable').remove(); // Temp fix for PDP, should move notifyme modal to GNB in the future
			}
			searchModal.addNotifyMeModal();
		}
	};

	searchModal.init();
})(jQuery);
	


// DC-3204 Open warranty modal on warranty link click on C0015
(function($) {
	if(!document.querySelector('.C0015')) return false;

	var $obj = $('.C0015');
	
    $obj.find('#warranty-modal-button').click(function(e) {
		e.preventDefault();
        $('#warranty-modal').modal('show');
    });
})(jQuery);

// Share
(function ($) {
	if (!document.querySelector('.share-common') && !document.getElementById('modal_help_library')) return false;
	initShareCommon = function () {
		var shareObj = $('.share-common');
		var sharePrint = shareObj.find('.article-print');
		var shareEmail = shareObj.find('.article-email');
		var shareLink = shareObj.find('.article-link');
		//share
		var shareFB = shareObj.find('.share-facebook');
		var shareTW = shareObj.find('.share-twitter');
		var sharePI = shareObj.find('.share-pinterest');
		//question section
		var shareModal = $('#modal_resource_search_copylink');
		$(document)
			.on("click", "[type=submit][form]", function (event) {
				event.preventDefault();
				var formId = $(this).attr("form"),
					$form = $("#" + formId).submit();
			})
			.on("keypress", "form input", function (event) {
				var $form;
				if (event.keyCode == 13) {
					$form = $(this).parents("form");
					if ($form.find("[type=submit]").length == 0 &&
						$("[type=submit][form=" + $(this).attr("form") + "]").length > 0) {
						$form.submit();
					}
				}
			});

		// adobe
		function adobeShare(obj, name) {
			// for PDP
			if ($('.C0009').length>0) {
				adobeTrackEvent('share-print', {
					products: [{sales_model_code : $('.C0009').data('adobe-salesmodelcode'), model_name: $('.C0009').data('adobe-modelname')}],
					social_service_name: name,
					page_event: {sns_share: true}
				});
			} else {
				adobeTrackEvent('sns-share', {social_service_name: name, page_event: {sns_share: true}});
			}
		}
		sharePrint.off('click').on('click', function (e) {
			e.preventDefault();
			adobeShare($(this), 'print');
			window.print();
		});
		shareEmail.off('click').on('click', function (e) {
			e.preventDefault();
			adobeShare($(this), 'email');
			var title = encodeURIComponent(document.title),
				hashCheck = new RegExp(/\#$/g);
			if(hashCheck.test(location.href)) {
				url = encodeURIComponent(location.href.replace(/\#$/g, ''));
			}else {
				url = encodeURIComponent(location.href);
			}

			if($(this).closest('.modal').length>0 && $(this).parent().find('.article-link').length>0) {
				// help library in modal (ex. symptoms)
				url = $(this).parent().find('.article-link').attr('data-url');
			}

			var mailto = 'mailto:?subject=' + title + '&body=' + url;
			location.href = mailto;

		});
		shareLink.off('click').on('click', function (e) {
			e.preventDefault();
			adobeShare($(this), 'link');
			//var ClipboardCopy = location.href;
			url = $(this).data('url');
			Clipboard.copy(url);
			shareModal.find('.modal-url').text(url);
		});
		shareFB.off('click').on('click', function (e) {
			e.preventDefault();
			adobeShare($(this), 'facebook');
			url = $(this).data('url');
			sendShareFb(url);
		});
		shareTW.off('click').on('click', function (e) {
			e.preventDefault();
			adobeShare($(this), 'twitter');
			url = $(this).data('url');
			title = $(this).data('title');
			via = $(this).data('via');
			
			// converting short Url script
			var shortUrl = e.currentTarget.getAttribute("data-short-url");
			if(shortUrl && shortUrl != null) {
				sendShareTw(shortUrl, title, via);
			}else {
				var ajaxData = $(this).closest(".sns-share").data();
				if(ajaxData.paramName) {
					var shortUrlParam = ajaxData.paramName+"="+url;
					// ajax.call(ajaxData.getUrl, shortUrlParam, 'jsonp', function (data) {
					// 	if(data && (data.shortUrl && data.shortUrl != null)) {
					// 		e.currentTarget.setAttribute("data-short-url", data.shortUrl);
					// 		sendShareTw(data.shortUrl, title, via);
					// 	}else {
					// 		sendShareTw(url, title, via);
					// 	}
					// });
					var ajaxUrl = 'https://www.lg.com/common/shorturl/getShortUrl.lgajax';
					if(ajaxData.getUrl) {
						ajaxUrl = ajaxData.getUrl;
					}
					$.ajax({
						type: "GET",
						timeout: 5e4,
						url: ajaxUrl,
						data: shortUrlParam,
						dataType: "jsonp",
						jsonp: "callback",
						success: $.proxy(function(data) {
							sendShareTw(data.shortUrl, title, via);
							//console.log(data);
						}, this)
					});
				}else {
					sendShareTw(url, title, via);
				}
			}
		});
		sharePI.off('click').on('click', function (e) {
			e.preventDefault();
			adobeShare($(this), 'pinterest');
			url = $(this).data('url');
			title = $(this).data('title');
			image = $(this).data('image');
			sendSharePi(url, title, image);
		});

		function openSns(url) {
			var winObj;
			winObj = window.open(url, "New Window", "width=600, height=800,scrollbars=yes");
		}

		function sendShareFb(url) {
			var shareurl = (url) ? url : document.location.href;
			url = "http://www.facebook.com/sharer/sharer.php?u=" + shareurl;
			openSns(url);
		}

		function sendShareTw(url, title, via) {
			var shareurl = (url) ? url : document.location.href;
			var text = (title) ? title : $("head title").text();
			var via2 = (via) ? via : 'LGUS';
			url = "https://twitter.com/intent/tweet?url=" + shareurl + "&text=" + encodeURIComponent(text) + "&via=" + via2;
			openSns(url);
		}

		function sendSharePi(url, title, image) {
			var shareurl = (url) ? url : document.location.href;
			var text = (title) ? title : $("head title").text();
			var img = (image) ? image : $("meta[property='og:image']").attr('content');
			url = "http://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(shareurl) + "&media=" + encodeURIComponent(img) + "&description=" + encodeURIComponent(text);
			openSns(url);
		}

		window.Clipboard = (function (window, document, navigator) {
			var textArea,
				copy;

			function isOS() {
				return navigator.userAgent.match(/ipad|iphone/i);
			}

			function createTextArea(text) {
				textArea = document.createElement('textArea');
				textArea.value = text;
				textArea.style.position = 'fixed';
				textArea.style.top = '0';
				textArea.style.left = '0';
				textArea.style.opacity = '0.0001';
				textArea.style.width = '100%';
				textArea.style.height = '100%';
				textArea.style.padding = '0';
				textArea.style.pointerEvents = "none";
				textArea.style.fontSize = '16px';


				document.body.appendChild(textArea);
			}

			function selectText() {
				var range,
					selection;

				if (isOS()) {
					range = document.createRange();
					range.selectNodeContents(textArea);
					selection = window.getSelection();
					selection.removeAllRanges();
					selection.addRange(range);
					textArea.setSelectionRange(0, 999999);
				} else {
					textArea.select();
				}
			}

			function copyToClipboard() {
				document.execCommand('copy');
				document.body.removeChild(textArea);
			}

			copy = function (text) {
				createTextArea(text);
				selectText();
				copyToClipboard();
			};
			return {
				copy: copy
			};
		})(window, document, navigator);
	};
	initShareCommon();
})(jQuery);

// Script to run before and After Printing
(function () {
	// Script to run before printing
	var beforePrint = function () {
		// lazyload image
		if ($('img.lazyload').length > 0) {
			$('img.lazyload').each(function () {
				$(this).attr('src', $(this).data('src')).removeClass('lazyload').addClass('lazyloaded');
			});
		}
	};
	// Script to run after printing
	var afterPrint = function () {
		// alert('Functionality to run after printing');
	};
	if (window.matchMedia) {
		var mediaQueryList = window.matchMedia('print');
		mediaQueryList.addListener(function (mql) {
			if (mql.matches) {
				beforePrint();
			} else {
				afterPrint();
			}
		});
	}
	window.onbeforeprint = beforePrint;
	window.onafterprint = afterPrint;
}());

// ESC key control
(function () {
	$(document).keyup(function (e) {
		if (e.keyCode == 27) { // escape key maps to keycode `27`
			// Close GNB Layer
			$('.navigation .for-desktop ul.depth1 li>a, .navigation .for-desktop ul.depth2 li>a, .navigation .for-desktop .tablet-layer, .navigation .gnb-login, .navigation .gnb-search-layer, .navigation .for-mobile .menu .menu-wrap').removeClass('active');
			$('.navigation .for-mobile .menu').removeClass('open');
			// Close Search Layer
			$('.search-area .search-layer').removeClass('active');
			// Close Tooltip Layer
			$('.tooltip-area').removeAttr('style');
			// video layer
			$('.video-modal').remove();
		}
	});
}());

// HTML Open Error
function htmlOpenError(htmldowntime, htmlopentime) {
	if ($('#htmlOpenError').length > 0) {
		if (htmldowntime) $('#htmlOpenError .htmldowntime').html(xssfilter(htmldowntime));
		if (htmlopentime) $('#htmlOpenError .htmlopentime').html(xssfilter(htmlopentime));
		$('#htmlOpenError').modal();
	}
}

// pagination branch
var isMobile;
(function ($) {
	isMobile = $('header.navigation').is('.mobile-device');
	var $pagination = $('.pagination'),
		$expander = $('.expander');
	if ($pagination.length > 0 && $expander.length > 0) {
		if (isMobile) {
			$pagination.hide();
			if($pagination.find('ul li').length>1) $expander.show();
		} else {
			$pagination.show();
			$expander.hide();
		}
	}
})(jQuery);

// skip to contents 
(function ($) {
	
	// init
	var $obj = $('.skip_nav a');
	var $navWrap = $('.navigation').closest('.container-fluid');
	if ($navWrap.length > 0) {
		var $target = $navWrap.next().find('div').not('div[id]').eq(0);
		if($target.hasClass('add-filter')) $target = $target.next();
		$target.attr('id', 'lgContents');
		
		/*LGEUS-215 : 20200331 add */
		var $targetId = $('.navigation').parents('.iw_component').next();
		if ( $targetId !== undefined ) {
			var getContainerID = null;
			function startCheckContainerId(){
				getContainerID = setInterval(function(){
					if ( $targetId.attr('id') !== undefined ) {
						stopCheckContainerId();
						if ($targetId.hasClass('add-filter')) $targetId = $targetId.next();
						$obj.eq(0).attr('href', '#'+ $targetId.attr('id') );
					}
					// DC-556 Start
					else{
						var $targetId2 = $('.iw_placeholder')
						if ( $targetId2.attr('id') !== undefined ) {
							stopCheckContainerId();
							if ($targetId.hasClass('add-filter')) $targetId = $targetId.next();
							$obj.eq(0).attr('href', '#'+ $targetId2.attr('id') );
						}
					}
					// DC-556 End
				}, 300);
			}
			function stopCheckContainerId() {
				clearInterval(getContainerID);
			}
			startCheckContainerId();
		}
		/*//LGEUS-215 : 20200331 add */
	} else {
		var $target2 = $('.navigation').next().find('div').not('div[id]').not('.breadcrumb').eq(0);
		/*LGEUS-215 : 20200331 add */
		if ( $target2 !== undefined ) {
			$target2.attr('id', 'lgContents');
		} else {
			var $targetId2 = $('.navigation').parents('.iw_component').next();
			var getContentID = null;
			function startCheckId(){
				getContentID = setInterval(function(){
					if ( $targetId2.attr('id') !== undefined ) {
						stopCheckId();
						$obj.eq(0).attr('href', '#'+ $targetId2.attr('id') );
					}
				}, 300);
			}
			function stopCheckId() {
				clearInterval(getContentID);
			}
			startCheckId();
		}
		/*LGEUS-215 : 20200331 add */
	}
	
	// click
	$obj.off('off').on('click', function (e) {
		/*LGEUS-11930 : 20190902 add */
		var link = $(this).attr('href').split('#')[1];
		if(link == 'lgAccHelp'){
			location.href = '/us/webaccessibility';
		}else{
			e.preventDefault();
		}
		/*LGEUS-11930 : 20190902 add */
		if ($('#'+link).length > 0) {
			$('#'+link).eq(0).attr('tabindex', 0).focus();
			if(link=='lgAccHelp' && $('.C0022.active').length>0) {
				$('.C0022.active').removeClass('showing');
			}
		}
	});

	/*LGEUS-215 : 20200331 add */
	var $breadcrumbList = $(document).find('.breadcrumb > ul > li').not(':last');
	if ( $breadcrumbList.length > 0 ) $breadcrumbList.append('<span class="breadcrumb-space">/</span>');
	// console.log($breadcrumbList)
	/*// LGEUS-215 : 20200331 add */

})(jQuery);


// page count
(function ($) {
	// usage
	// <div class="js-page-count" data-count-url="/data-ajax/mkt/pageCount.json" data-param="modelId=testmodelId"></div>
	var $el = $('.js-page-count');
	if ($el.length > 0) {
		$el.each(function () {
			var url = $(this).data('count-url');
			var param = $(this).data('param');
			if (url && param) {
				ajax.call(url, param, 'json', function (data) {
					// do nothing
				});
			}
		});
	}
})(jQuery);

// adobe (cs > psp page > download)
(function ($) {
	if($('.support-downloads').length>0) {
		$obj=$('.support-downloads .list>li .name a.link-text');
		$obj.on('click', function() {
			var fileName = "";
			if($(this).closest('li').hasClass('manuals')) {
				// cs > psp page > Manuals & Documents
				var $pobj = $('.support-product-area .text-block .model');
				var tempname = $pobj.text();
				if($pobj.find('.name').length>0) tempname = tempname.replace($pobj.find('.name').text(), '');
				fileName = changeTitleFormat($(this).closest('li').find('.type').text()) + ':' + changeTitleFormat(tempname) + ':' + changeTitleFormat($(this).text());
				adobeTrackEvent('cs-psp-download', {download_file_type : "manuals_documents", download_file_name: fileName, page_event : {cs_file_download : true}});
			} else if($(this).closest('li').hasClass('software')) {
				// cs > psp page >Software & Drivers
				var $pobj2 = $('.support-product-area .text-block .model');
				var tempname2 = $pobj2.text();
				if($pobj2.find('.name').length>0) tempname2 = tempname2.replace($pobj2.find('.name').text(), '');
				fileName = changeTitleFormat($(this).closest('li').find('.type').text()) + ':' + changeTitleFormat(tempname2) + ':' + changeTitleFormat($(this).text());
				adobeTrackEvent('cs-psp-download', {download_file_type : "software_drivers", download_file_name: fileName, page_event : {cs_file_download : true}});
			}
		});
	}
})(jQuery);

// adobe (cs > dispatch portal > sign out)
(function ($) {
	$('.user-info-wrap .extra-area a.btn-exception-outline-xs').click(function() {
		adobeTrackEvent('cs-dispatch-portal-signout', {page_event : {partner_sign_out : true}});
	});
})(jQuery);

/* LGEGMC-234 20200526 add */ /* BTOBGLOBAL-61 20200602 modify */
$(function() {
	if ( $('.container-fluid:has(.navigation)').length && !$('main').length ) {
		$('.container-fluid:has(.navigation) ~ div,.container-fluid.iw_section:has(.navigation) ~ section').not(':has(.footer-box)').wrapAll('<main></main>');
	}

	/* LGEUS-631 20200623 add */
	var $footerBlankLnk = $('.footer-main-contents').find('a.ico-blank');
	if ($footerBlankLnk.length) {
		var hiddenIconMsgHTML = '<span class="visually-hidden">(opens in a new window)</span>';	
		$(hiddenIconMsgHTML).appendTo($footerBlankLnk);
	}
	/* //LGEUS-631 20200623 add */
});
/* //LGEGMC-234 20200526 add */ /* //BTOBGLOBAL-61 20200602 modify */

// LGEUS-130 Pricespider Universal Tracking Setup
var PriceSpider=PriceSpider||{};PriceSpider.UTID={utidCookieName:"ps-utid",utParamCookieName:"ps-utparam",cookieDurationMin:60,setCookie:function(i,e,o,t,a,n){var r=new Date;r.setTime(r.getTime()),o&&(o=1e3*o*60);var s=new Date(r.getTime()+o);document.cookie=i+"="+encodeURIComponent(e)+(o?"; expires="+s.toGMTString():"")+(t?"; path="+t:"")+(a?"; domain="+a:"")+(n?"; secure":"")},init:function(i){for(var e=i?i.length:0,o=0;e>o;o++){var t=i[o],a=location.search?location.search.match(new RegExp("[?&]"+t.name+"=([^&#]+)","i")):null;if(a){if(t.values){for(var n=!1,r=a[1].toLowerCase(),s=t.values.length,c=0;s>c;c++)if((t.values[c]?t.values[c].toLowerCase():null)==r){n=!0;break}if(!n)continue}this.setCookie(this.utidCookieName,a[1],this.cookieDurationMin,"/",location.hostname),this.setCookie(this.utParamCookieName,t.name,this.cookieDurationMin,"/",location.hostname);break}}},set:function(i,e){if(!i||"string"!=typeof i)throw"Invalid or missing utid. Expected String";if(e&&"string"!=typeof e)throw"Invalid utParamName";this.setCookie(this.utidCookieName,i,this.cookieDurationMin,"/",location.hostname),e&&this.setCookie(this.utParamCookieName,e,this.cookieDurationMin,"/",location.hostname)}},PriceSpider.UTID.init([{"name":"cmpid"},{"name":"utid"}]);

/* LGEUS-126 : 20200529 add */
// $(document).ready(function(){
// 	var url = '/us/mkt/ajax/retrieveEmailSignUpHtml';
// 	var currentUrl = document.location.pathname;
// 	var bizType = 'B2B';
// 	if($('.navigation').length>0) {
// 		if(!$('.navigation').hasClass('b2b')) {
// 			bizType = 'B2C'
// 			return false;
// 		}else{
// 			bizType = 'B2B'
// 		}
// 	}
// 	var param = {'requestUrl':currentUrl, 'bizType' : bizType}
// 	ajax.call(url, param, 'json', function (data) {
// 		if(!data) return false;
		
// 		if(data.data[0].emailSignUpHtml != undefined){
// 			$('header.navigation').before(data.data[0].emailSignUpHtml);
			
// 			var emailSignUp = {
// 				banner : document.getElementById('joinUsBanner'),
// 				form : document.getElementById('emailSignUpForm'),	
// 				closeBtn : document.querySelectorAll('#joinUsBanner .close a'),
// 				joinUsWarp : document.querySelectorAll('.join-us-wrap'),
// 				joinUsComplete : document.querySelectorAll('.join-us-complete'),
// 				cookieName : 'US_emailSignUpOpenFlag',
// 				init : function(){
// 					emailSignUp.initBanner();
// 					emailSignUp.addEvent();					
// 				},
// 				addEvent : function(){
// 					$(emailSignUp.form).submit(function(e) {
// 						e.preventDefault();
// 						if($.fn.checkValidation == undefined){
// 							$.getScript( "/lg5-common/library/form-validation.min.js" )
// 							.done(function(){
// 								emailSignUp.formSubmitAction();	
// 							});				
// 						}else{				
// 							emailSignUp.formSubmitAction();
// 						}	
						
// 					});
// 					$(emailSignUp.closeBtn).on('click', function(e){
// 						e.preventDefault();
// 						emailSignUp.setCookie(emailSignUp.cookieName, true);
// 						$(emailSignUp.banner).removeClass('active');
// 					});
// 				},
// 				getCookie : function(name){
// 					if($.cookie(name)){
// 						return decodeURIComponent($.cookie(name))
// 					}else{
// 						$.cookie(name);
// 					}
// 				},
// 				setCookie : function(name, value){
// 					var lh = location.host;
// 					var mydomain = '.lg.com';
// 					if(lh.indexOf('lge.com')>=0){
// 						mydomain = '.lge.com';
// 					}else if(lh.indexOf('localhost') >= 0){
// 						mydomain = 'localhost';
// 					}
// 					var domain = {
// 						path : '/',
// 						domain : mydomain,
// 						expires : 7
// 					}

// 					$.cookie(name, encodeURIComponent(value), domain);
// 				},
// 				initBanner : function(){
// 					var self = this;
// 					$(self.joinUsComplete).hide();
// 					if(self.getCookie(self.cookieName) == 'true'){
// 						$(self.banner).removeClass('active');
// 					}else{
// 						$(self.banner).addClass('active');
// 					}
// 				},
// 				formSubmitAction : function(){
// 					if($(emailSignUp.form).checkValidation({trigger:true})) {
// 						var $form = $(emailSignUp.form),
// 					  	url = $form.attr('action');
// 						$.post(url, $form.serialize());

// 						$(emailSignUp.joinUsWarp).hide();
// 						$(emailSignUp.joinUsComplete).show();
// 						dataLayer.push({'event': 'formSubmission'});
// 						emailSignUp.setCookie(emailSignUp.cookieName, true);
// 					}	
// 				}
// 			}
// 			emailSignUp.init();
			
// 			var ju = {
// 				el: document.getElementById('joinUsBanner'),
// 				init: function(){
// 					$(ju.el).each(function(){ 
// 		                var banner = $(this);
// 		                var bannerOffset = banner.offset().top;
// 		                var $w = $(window);
// 		                $w.scroll(function(){
// 		                    var wScroll = $(this).scrollTop();
// 		                    if(wScroll > bannerOffset){
// 		                        $(banner).addClass("sticky-active");
// 		                    }else{
// 		                        $(banner).removeClass("sticky-active");
// 		                    }
// 		                });
// 		            });
// 				}
// 			};
// 		    ju.init();
		    
// 		    var $more = $('.more');
// 		    if ( $(window).width() > 767) {      
// 		        $more.attr('tabindex', '-1');
// 		    } 
// 		    else {
// 		        $more.attr('tabindex', '0');
// 		        $more.on('click', function(e){
// 		        	e.preventDefault();
// 		            var $this = $(this);     
// 		            $this.addClass('open').attr('aria-expanded', true);
// 		            $this.parent().parent().parent().find('.column-box').addClass('open');
// 		        });
// 		    }
		    
// 		    $( window ).resize(function() {
// 		        if ( $(window).width() > 767) {      
// 		            $more.attr('tabindex', '-1');
// 		        } 
// 		        else {
// 		            $more.attr('tabindex', '0');
// 		            $more.on('click', function(){
// 		                var $this = $(this);     
// 		                $this.addClass('open').attr('aria-expanded', true);
// 		                $this.parent().parent().parent().find('.column-box').addClass('open');
// 		            });
// 		        }
// 		    });
// 		}
// 	});
// });
/*// LGEUS-126 : 20200529 add */
$(document).ready(function(){
	var curPage = 1;
	var icons = {
		StarOutlined: '<svg fill="white"><path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path></svg>',
		InfoOutlined: '<svg fill="white" viewBox="0 0 24 24"><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>',
		AccessAlarmOutlined: '<svg fill="white" viewBox="0 0 24 24"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>',
		AccessTimeOutlined: '<svg fill="white" viewBox="0 0 24 24"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>',
		LocalShippingOutlined: '<svg fill="white" viewBox="0 0 24 24"><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5 1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>',
		ShoppingBagOutlined: '<svg fill="white" class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-1k33q06" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ShoppingBagOutlinedIcon"><path d="M18 6h-2c0-2.21-1.79-4-4-4S8 3.79 8 6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-6-2c1.1 0 2 .9 2 2h-4c0-1.1.9-2 2-2zm6 16H6V8h2v2c0 .55.45 1 1 1s1-.45 1-1V8h4v2c0 .55.45 1 1 1s1-.45 1-1V8h2v12z"></path></svg>',
		LocalOfferOutlined: '<svg fill="white" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LocalOfferOutlinedIcon"><path d="m21.41 11.58-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42M13 20.01 4 11V4h7v-.01l9 9z"></path><circle cx="6.5" cy="6.5" r="1.5"></circle></svg>',
		DiscountOutlined: '<svg fill="white" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DiscountOutlinedIcon"><path d="M12.79 21 3 11.21v2c0 .53.21 1.04.59 1.41l7.79 7.79c.78.78 2.05.78 2.83 0l6.21-6.21c.78-.78.78-2.05 0-2.83z"></path><path d="M11.38 17.41c.39.39.9.59 1.41.59.51 0 1.02-.2 1.41-.59l6.21-6.21c.78-.78.78-2.05 0-2.83L12.62.58C12.25.21 11.74 0 11.21 0H5C3.9 0 3 .9 3 2v6.21c0 .53.21 1.04.59 1.41zM5 2h6.21L19 9.79 12.79 16 5 8.21z"></path><circle cx="7.25" cy="4.25" r="1.25"></circle></svg>',
		BuildOutlined: '<svg fill="white" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BuildOutlinedIcon"><path d="m22.61 18.99-9.08-9.08c.93-2.34.45-5.1-1.44-7C9.79.61 6.21.4 3.66 2.26L7.5 6.11 6.08 7.52 2.25 3.69C.39 6.23.6 9.82 2.9 12.11c1.86 1.86 4.57 2.35 6.89 1.48l9.11 9.11c.39.39 1.02.39 1.41 0l2.3-2.3c.4-.38.4-1.01 0-1.41m-3 1.6-9.46-9.46c-.61.45-1.29.72-2 .82-1.36.2-2.79-.21-3.83-1.25C3.37 9.76 2.93 8.5 3 7.26l3.09 3.09 4.24-4.24-3.09-3.09c1.24-.07 2.49.37 3.44 1.31 1.08 1.08 1.49 2.57 1.24 3.96-.12.71-.42 1.37-.88 1.96l9.45 9.45z"></path></svg>',
		ContentCopy: '<svg fill="white" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ContentCopyIcon"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2m0 16H8V7h11z"></path></svg>',
	  };

	  var curDomain = document.location.hostname;
	  if(!curDomain.startsWith("sso") && !$('.navigation').hasClass('b2b')) {
		  var pencilbannerUrl = "/us/mkt/ajax/getPencilbannerInfo";
		  if($('#curDomain').length > 0) {
			  pencilbannerUrl = $('#curDomain').val() + pencilbannerUrl;
		  }
  
		  ajax.call(pencilbannerUrl, { "cache" : true }, 'json', function (d) {
			var result = JSON.parse(d.result);
			var statusCode = result.status;
			if(200 == statusCode) {
				var data = result.data;
				if(data) {
					var isVisible = d.isVisible;
					if(isVisible == "true") {
						var bannerCollection = data.entry.pencil_banner[0].banner_collection.filter(function(elem) {
							var now = new Date().getTime();
							if (elem.end_date) {
								var endDate = new Date(elem.end_date).getTime();
								var distance = endDate - now;
								if (distance < 0) {
									return false;
								}
							}
							if (elem.start_date) {
								var startDate = new Date(elem.start_date).getTime();
								var distance = now - startDate;
								if (distance < 0) {
									return false;
								}
								return true;
							}
							return false;
						});

						if(bannerCollection.length == 1) {
							$('.gallery-slick-prev').hide();
							$('.gallery-slick-next').hide();
						}

						var renderCTAs = function (cta1) {
							if (Array.isArray(cta1) && cta1.length > 0) {
								return cta1.map(function (elem) {
									return '<a style="display: inline" class="gallery-notices-bar-content-link" href="' + elem.link + '" target="' + elem.target + '" title="' + elem.title + '">' + elem.title + '</a>';
								}).join('');
							}
							return '';
						};

						var renderPagination = function() {
							$('.gallery-notices-bar-page').html((curPage + "/" + bannerCollection.length));
							$('.gallery-notices-bar-page').css('line-height', $($('.gallery-notices-bar-page')[0]).height() + 'px');
						};

						var html = bannerCollection.map(function(elem, index) {
							if (elem.start_date) {
								var startDate = new Date(elem.start_date).getTime();
								var now = new Date().getTime();
								var distance = now - startDate;
								if (distance < 0) {
									return '';
								}
							}
							if (elem.end_date && elem.enable_countdown) {
								var countDownDate = new Date(elem.end_date).getTime();
								var now = new Date().getTime();
								var distance = countDownDate - now;
								if (distance < 0) {
									return '';
								}
								var updateTimerHtml = function() {
									var now = new Date().getTime();
									var distance = countDownDate - now;
									// Time calculations for days, hours, minutes and seconds
									var days = Math.floor(distance / (1000 * 60 * 60 * 24));
									var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
									var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
									var seconds = Math.floor((distance % (1000 * 60)) / 1000);
									
									$('#timer-' + index).html(days.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) 
										+ ':' + hours.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
										+ ':' + minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
										+ ':' + seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}));
								
									if (distance < 0) {
										clearInterval(x);
										$('#timer-' + index).html("00 : 00 : 00 : 00");
										clearInterval(autoPlayInterval);
										window.location.reload();
									}
								};
								// Update the count down every 1 second
								var x = setInterval(function() {
									updateTimerHtml();
								}, 1000);
							}

							var initStyle = 'transform:translateX(' + (-100*index) + ');z-index:' + (index === 0 ? 1 : 0) + ';opacity: ' + (index === 0 ? 1 : 0);
							
							var icon = elem.icon.icons==null ? "" : elem.icon.icons;
							if(icon != "" && icon.includes("Icon")) {
								icon = elem.icon.icons.substring(0, elem.icon.icons.length-4);
							}
							var iconHtml = "";
							if(icon != "") {
								if(icons[icon] == undefined || icons[icon] == 'undefined') {
									iconHtml = '<div class="gallery-notices-bar-content-icon"></div>';
								} else {
									iconHtml = '<div class="gallery-notices-bar-content-icon">' + icons[icon] + '</div>';
								}
							} else {
								iconHtml = '<div class="gallery-notices-bar-content-icon"></div>';
							}
							
							return '<div class="gallery-title" style="' + initStyle + '">'
								+ iconHtml
								+ '<div class="gallery-title-main">'
								+ elem.message
								+ '<p id="timer-' + index + '"></p>'
								+ '<a style="display: inline" href="javascript:;" data-value="' + elem.coupon_code + '" class="gallery-notices-bar-content-link gallery-copy">'
								+ elem.coupon_label
								+ '<div class="gallery-copy-alert" style="display: none">Coupon code ' + elem.coupon_code + ' has been copied to your clipboard</div>'
								+ '</a>'
								+ renderCTAs(elem.cta1)
								+ '</div>'
								+ '</div>';
						}).join('');

						$('#gallerySlidingContent').html(html);

						renderPagination();
						
						var autoPlay = function() {
							slickNextHandler();
						};
						var autoPlayInterval = setInterval(autoPlay, 4500);

						var slickNextHandler = function () {
							if (curPage + 1 > bannerCollection.length) {
								curPage = 1;
							} else {
								curPage += 1;
							}
							$('.gallery-title').css('z-index', 0).animate({ opacity: 0 }, 100, function () {
								$('.gallery-title').eq(curPage - 1).css('transform', 'translateX(' + (-100*(curPage - 1)) + '%)').animate({ opacity: 1 }, 100).css('z-index', 1);
							});
							renderPagination();
						}

						$('.gallery-slick-next').off().on('click', function () {
							clearInterval(autoPlayInterval);
							slickNextHandler();

							setTimeout(function() {
								clearInterval(autoPlayInterval);
								autoPlayInterval = setInterval(autoPlay, 4500);
							}, 4500);
						});

						$('.gallery-copy').off().on('click', function (e) {
							var copyValue = $(e.target).data('value');
							navigator.clipboard.writeText(copyValue);
							var alertElem = $('#copyAlert');
							alertElem.css('z-index', '100');
							alertElem.html('Coupon code <strong>' + copyValue + '</strong> has been copied to your clipboard').fadeIn();
							setTimeout(function () {
								alertElem.fadeOut();
							}, 3000)
						});
						
						$('.gallery-slick-prev').off().on('click', function () {
							clearInterval(autoPlayInterval);
							if (curPage - 1 < 1) {
								curPage = bannerCollection.length;
							} else {
								curPage -= 1;
							}

							$('.gallery-title').css('z-index', 0).animate({ opacity: 0 }, 100, function () {
								$('.gallery-title').eq(curPage - 1).css('transform', 'translateX(' + (-100*(curPage - 1)) + '%)').animate({ opacity: 1 }, 100).css('z-index', 1);
							});

							renderPagination();
							setTimeout(function() {
								clearInterval(autoPlayInterval);
								autoPlayInterval = setInterval(autoPlay, 4500);
							}, 4500);
						});

						if($('.support-gallery').is(":hidden") && bannerCollection.length > 0) {
							$('.support-gallery').show();
							$('.gallery-notices-bar-page').css('line-height', $($('.gallery-notices-bar-page')[0]).height() + 'px');
							if(!window.location.hash) {
								window.scrollTo(0, 0);
							}
						}
					} else {
						$('.support-gallery').hide();
					}
				}
			}
		});
	}
});
	
// Js to limit phone number inputs
$(document).ready(function(){
  $(".checkPhoneNumber").on("input", function(e) {
    var sanitized = $(this).val().replace(/[^\d]/g, ''); // Remove non-numeric characters 
    if (sanitized !== $(this).val()) {
      $(this).val(sanitized);
    }
    if ($(this).val().length > 12) {
      $(this).val($(this).val().substring(0, 12)); // Limit to 12 characters
    }
  });

  $("#checkPhoneNumber").on("paste", function(e) {
    e.preventDefault();
    var pastedText = e.originalEvent.clipboardData.getData('text');
    var sanitized = pastedText.replace(/[^\d]/g, ''); // Remove non-numeric characters
    $(this).val(sanitized.substring(0, 12)); // Limit to 12 characters after paste
  });
});